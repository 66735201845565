import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import Loader from "../components/Loader";
import Table from "../components/Table";
import { useAppContext } from "../context/AppContext";
import { formatStandard } from "../helpers/DateTime";
import useSeason from "../hooks/api/useSeason";
import useTeam from "../hooks/api/useTeam";
import useMount from "../hooks/useMount";
import Filter from "./Filter";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { EMPTY_IMAGE } from "../constants/Image";

// import Filter from "./Filter";

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Name = styled.span(({ head, theme }) => ({
  flex: 1,
  paddingLeft: head ? theme.spacing(5) : 0, // theme.spacing(1),
  paddingRight: head ? 0 : theme.spacing(5),
}));

const Logo = styled.img({
  width: 25,
  height: 25,
  borderRadius: "100%",
  objectFit: "cover",
  marginRight: 10,
});

function Teams({ id }) {
  const { setPageTitle } = useAppContext();

  const history = useHistory();
  const [showFilter, setShowFilter] = useState();
  const [filterText, setFilterText] = useState();

  const { response = [], loading, getTeams } = useSeason({});
  const { remove } = useTeam({});

  const filtered = useMemo(() => {
    if (!filterText) {
      return response || [];
    }

    return response?.filter(({ name }) =>
      `${name}`.toLowerCase().includes(`${filterText}`.toLowerCase())
    );
  }, [filterText, response]);

  useMount(() => {
    setPageTitle("Team");
  });

  useEffect(() => {
    getTeams(id);
  }, [id]);

  const headCells = [
    {
      id: "name",
      numeric: false,
      label: "Name",
      custom: renderNameAndAction,
    },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: (item) => formatStandard(item.createdAt),
    },
  ];

  async function removeTeam(id) {
    const allIndex = response.findIndex((s) => s.id === id);
    if (allIndex > -1) {
      // only splice array when item is found
      response.splice(allIndex, 1); // 2nd parameter means remove one item only
    }

    await remove(id);
  }

  function renderNameAndAction(item) {
    const { id, name, image } = item;
    return (
      <StatusContent>
        <Logo src={image || EMPTY_IMAGE} />
        <Name>{name}</Name>
        <IconButton size="small" onClick={() => history.push(`/team/${id}`)}>
          <EditIcon fontSize="inherit" />
        </IconButton>
        <IconButton size="small" onClick={() => removeTeam(id)}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </StatusContent>
    );
  }

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async ({ name } = {}) => {
      setShowFilter();
      setFilterText(name);
    },
  };

  const tableProps = {
    headCells,
    items: filtered,
    rowsPerPage: 50,
    title: "Teams",
    uniqueId: "id",
    defaultSort: "createdAt",
    defaultOrder: "desc",
    onAdd: () => {
      history.push(`/team/${id}/add`);
    },
    onFilter: () => {
      setShowFilter(true);
    },
  };

  return (
    <>
      {loading ? <Loader paper /> : <Table {...tableProps} />}
      <Filter {...filterProps} />
    </>
  );
}

export default Teams;
