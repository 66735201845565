import React from "react";
import useResetSubmasterDownlines from "../hooks/api/useResetSubmasterDownlines";
import styled from "styled-components";
import TextField from "../components/TextField";
import { useForm } from "react-hook-form";
import { REQUIRED_RULE } from "../helpers/Form";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { FormProvider } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import bgImage from "../assets/bg.jpg";
import logoImage from "../assets/logo.png";
import Copyright from "../components/Copyright";
import Loader from "../components/Loader";
import { Root, Container as LibContainer } from "../components/SharedLayout";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { Card, CardContent } from "@material-ui/core";

const SubmasterInput = styled(TextField).attrs({
  label: "Submaster",
  name: "submasterId",
  rules: REQUIRED_RULE,
  autoFocus: true,
})({});

function Reset() {
  const { loading, reset } = useResetSubmasterDownlines();

  const { setMessage } = useAppContext();

  const form = useForm();
  const { handleSubmit } = form;

  async function onSubmit(data) {
    try {
      const { submasterId } = data;
      await reset(submasterId);

      setMessage("Reseted");
    } catch (ex) {
      setMessage(ex);
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SubmasterInput />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
}

export default Reset;
