import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";
// import useFetch from "../useFetch";

function useResetSubmasterDownlines() {
  const { loading, del } = useAuthFetch({});

  function reset(id) {
    return del({
      url: `${API_URL}/user/submaster/${id}/account`,
    });
  }

  return { loading, reset };
}

export default useResetSubmasterDownlines;
