import Button from "@material-ui/core/Button";
import LibDialog from "@material-ui/core/Dialog";
import LibDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import LibDivider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";
import Loader from "../components/Loader";
import MatchCard from "../components/MatchCard";
import { formatStandard } from "../helpers/DateTime";

const Row = styled.div(({ space, theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  marginBottom: theme.spacing(space),
}));

const Col = styled.div(({ align }) => ({
  display: "flex",
  flex: 1,
  justifyContent: align === "right" ? "flex-end" : "",
}));

const ReceiptNo = styled(Typography).attrs({
  variant: "subtitle2",
})(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const Head = styled(Typography).attrs({
  variant: "overline",
  color: "textSecondary",
})({});

const Name = styled(Typography).attrs(({ main }) => ({
  variant: main ? "body1" : "subtitle2",
  color: "textSecondary",
}))(({ error, main, theme }) => ({
  color: error ? "#FF0000" : "",
  fontWeight: error ? "bold" : "",
  //   paddingLeft: main ? 0 : theme.spacing(1),
}));

const Price = styled(Typography).attrs({
  variant: "subtitle2",
  color: "textSecondary",
})(({ error, main, theme }) => ({
  color: error ? "#FF0000" : "",
  fontWeight: "bold",
  //   paddingLeft: main ? 0 : theme.spacing(1),
}));

const Total = styled(Typography).attrs(({ main }) => ({
  variant: "h6",
}))(({ main, theme }) => ({
  //   paddingLeft: main ? 0 : theme.spacing(1),
}));

const Footer = styled(Typography).attrs(({ main }) => ({
  variant: "h6",
}))({
  fontSize: "0.95em",
  textAlign: "center",
  marginBottom: 10,
});

const Divider = styled(LibDivider)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const BottomText = styled(Typography).attrs(({ main }) => ({
  variant: "body2",
  color: "textSecondary",
}))(({ main }) => ({
  fontWeight: main && "bold",
}));

const ConfirmButton = styled(Button)({
  backgroundColor: "rgb(26,146,77)",
  borderRadius: 18,
  letterSpacing: "0.125em",
  color: "#fff",

  "&:hover": {
    backgroundColor: "#357a38",
  },
});

const CancelButton = styled(Button)({
  letterSpacing: "0.125em",
});

const DialogActions = styled(LibDialogActions)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  marginBottom: 16,
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const Dialog = withStyles((theme) => ({
  paper: {
    borderRadius: 0,
    width: `calc(100% - 32px)`,
    margin: 16,
  },
}))(LibDialog);

function ReceiptConfirmation({
  item,
  match,
  open,
  loading,
  onConfirm,
  onClose,
}) {
  if (!item) {
    return null;
  }

  if (loading) {
    return (
      <Dialog fullWidth maxWidth="sm" open disableBackdropClick>
        <Loader />
      </Dialog>
    );
  }

  const { products = [], remark, paidBy, createdAt } = item;

  const total = item?.products.reduce((prev, odd) => prev + +odd.betAmount, 0);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogContent>
        <ReceiptNo>Confirmation</ReceiptNo>
        <Row>
          <Col>
            <Head>Products</Head>
          </Col>
          <Col align="right">
            <Head>Odds</Head>
          </Col>
          <Col align="right">
            <Head>bets</Head>
          </Col>
        </Row>
        <Divider />
        {products.map((prd) => (
          <Row space={2}>
            <Col>
              <Name error={!!prd.remark}>{prd.score}</Name>
            </Col>
            <Col align="right">
              <Name error={!!prd.remark}>{prd.remark ?? prd.amount}</Name>
            </Col>
            <Col align="right">
              <Price error={!!prd.remark}>${prd.betAmount}</Price>
            </Col>
          </Row>
        ))}
        <Divider />
        <Row space={2}>
          <Col></Col>
          <Col>
            <Total>Total</Total>
          </Col>
          <Col align="right">
            <Total main>$ {total}</Total>
          </Col>
        </Row>
        <Divider />

        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Remark</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{remark || "-"}</BottomText>
          </Col>
        </Row>

        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Paid by</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{paidBy}</BottomText>
          </Col>
        </Row>
        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Paid at</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{formatStandard(createdAt)}</BottomText>
          </Col>
        </Row>
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={onClose} color="primary">
          Cancel Bet
        </CancelButton>
        <ConfirmButton onClick={onConfirm}>Confirm Bet</ConfirmButton>
      </DialogActions>
      <Footer>Confirmed bet cannot be refund.</Footer>
      <MatchCard {...match} id={null} />
    </Dialog>
  );
}

export default ReceiptConfirmation;
