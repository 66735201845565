import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";

import DatePicker from "../components/Datepicker";
import FilterChips from "../components/FilterChips";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { numberWithCommas } from "../helpers/String";
import useMatchByCode from "../hooks/api/useMatchByCode";
import useMatchByDate from "../hooks/api/useMatchByDate";
import useOverallWinLoseReportList from "../hooks/api/useWinLoseReportList.Overall";
import useForm from "../hooks/useForm";
import useQueryString from "../hooks/useQuerystring";

// import TextField from "@material-ui/core/TextField";
const MatchNoInput = styled(TextField).attrs({
  name: "matchNo",
  label: "Match No(s)",
  placeholder: "e.g, WC001,WC002,WC003",
})({});
const StartDateInput = styled(DatePicker).attrs({
  name: "startDate",
  label: "Start Date",
})({});

const EndDateInput = styled(DatePicker).attrs({
  name: "endDate",
  label: "End Date",
})({});

const UsernameInput = styled(TextField).attrs({
  name: "userId",
  label: "User Id",
})({});

const Value = styled(Typography).attrs({
  variant: "h3",
})(({ $error, $primary }) => ({
  marginBottom: 16,
  color: $primary ? "#000" : $error ? red[300] : green[500],
  wordBreak: "break-word",
  textAlign: "center",
}));

const Title = styled(Typography).attrs({
  variant: "h6",
  color: "textSecondary",
})({
  marginTop: 10,
  marginBottom: 20,
  textAlign: "center",
  // color: "#fff",
});

const SummaryCard = styled(Paper)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: 16,
  marginTop: 8,
  // paddingBottom: 16,
  paddingLeft: 16,
  paddingRight: 16,
  // minHeight: 155,
  // height: "100%",
});

const ALL_SELECTION = ["All", "Downline", "Own", "Upline"];

function WinLoseDateReport() {
  const { setPageTitle } = useAppContext();

  const { user } = useAuthContext();
  const { role: userRole } = user ?? {};
  const isMasterRole = userRole === ROLE.master;
  const isSubAccRole = userRole === ROLE.subaccount;

  const [searchedMatchNos, setSearchedMatchNos] = useQueryString("mn");
  const [searchedStartedDate, setSearchedStartedDate] = useQueryString("sd");
  const [searchedEndDate, setSearchedEndDate] = useQueryString("ed");
  const [searchedUserId, setSearchedUserId] = useQueryString("u");

  const { refresh: getMatchsByDate } = useMatchByDate();
  const { refresh: getMatchsByCode } = useMatchByCode();

  const { loading, search } = useOverallWinLoseReportList();

  const [report, setReport] = useState();
  const [selectedFilter, setSelectedFilter] = useState([]);

  const selections = isSubAccRole ? [ALL_SELECTION[1]] : ALL_SELECTION;

  const isOriginal = selectedFilter.includes(ALL_SELECTION[0]);
  const isDownline = selectedFilter.includes(ALL_SELECTION[1]);
  const isOwn = selectedFilter.includes(ALL_SELECTION[2]);
  const isUpline = selectedFilter.includes(ALL_SELECTION[3]);

  const form = useForm();
  const { handleSubmit, reset } = form;

  // const matchNos = (dMatchs || []).map((s) => s.matchNo);

  useEffect(() => {
    setPageTitle("Win/Lose Report");

    reset({
      matchNo: searchedMatchNos,
      startDate: searchedStartedDate && +searchedStartedDate,
      endDate: searchedEndDate && +searchedEndDate,
      userId: searchedUserId,
    });

    if (searchedMatchNos || (searchedStartedDate && searchedEndDate)) {
      handleSubmit(onSubmit)();
    }
  }, []);

  function formatAmount(amount = 0) {
    if (amount >= 0) {
      return `${numberWithCommas(amount.toFixed(2))}`;
    }

    return `-${numberWithCommas(Math.abs(amount.toFixed(2)))}`;
  }

  async function searchByCodes(data) {
    const { matchNo: code } = data;
    const matchs = await getMatchsByCode({ code });
    return matchs.map((s) => s.id);
  }

  async function searchByDate(data) {
    const { startDate: sd, endDate: ed } = data;
    const startDate = moment(sd).startOf("day").toJSON();
    const endDate = moment(ed).endOf("day").toJSON();

    const matchs = await getMatchsByDate({
      startDate,
      endDate,
    });

    return matchs.map((s) => s.id);
  }

  function sumAllReport(all, report, upline, firstId) {
    // more
    const rTable = report.reduce((r, item) => {
      return {
        ...r,
        [item.userName]: item,
      };
    }, {});

    all = fillInMissingUsers(all, rTable, firstId);

    if (all.length === 0) {
      return report;
    }

    // less
    const raw = all.map((prev) => {
      const r = rTable[prev.userName];
      if (!r) {
        return prev;
      }

      return {
        turnovers: prev.turnovers + r.turnovers,
        commission: prev.commission + r.commission,
        earnCommission: prev.earnCommission + r.earnCommission,
        payout: prev.payout + r.payout,
        total: prev.total + r.total,
        userName: prev.userName,
        name: prev.name || prev.userName,
        perc: 0,
      };
    });

    if (!upline) {
      return raw;
    }

    const total_amount = raw.pop();
    const { turnovers: uplineTo } = raw[0];

    const total = total_amount.turnovers + uplineTo;

    const updated = raw.map((r) => {
      const { userName, turnovers } = r;
      if (userName === upline) {
        return {
          ...r,
          perc: formatAmount((uplineTo / total) * 100),
        };
      }

      return {
        ...r,
        perc: formatAmount((turnovers / total) * 100),
      };
    });

    total_amount.perc = 100;
    return [...updated, total_amount];
  }

  function fillInMissingUsers(all, rTable, firstId) {
    const reportUsername = Object.keys(rTable);

    const leftOverUsername = reportUsername
      .map((userName) => {
        const found = all.find((s) => s.userName === userName);
        if (found) {
          return undefined;
        }

        return userName;
      })
      .filter((s) => !!s);

    if (leftOverUsername.length === 0) {
      return all;
    }

    const list = [
      ...all,
      ...leftOverUsername.map((s) => ({
        turnovers: 0,
        commission: 0,
        earnCommission: 0,
        payout: 0,
        total: 0,
        userName: s,
        name: rTable[s].name,
        perc: 0,
      })),
    ];

    

    list.sort((a, b) => {
      const fa = a.userName.toLowerCase();
      const fb = b.userName.toLowerCase();

      if(fa === firstId || fb === firstId) {
        return 99;
      }

      if(fa === 'total_amount' || fb === 'total_amount') {
        return -99;
      }

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }

      return 0;
    });
    return list;
  }

  async function onSubmit(data) {
    const { matchNo: mn, userId, startDate: sd, endDate: ed } = data;
    const api = !!mn ? searchByCodes(data) : searchByDate(data);

    const startDate = moment(sd).startOf("day");
    const endDate = moment(ed).endOf("day");

    setSearchedMatchNos(mn);
    setSearchedStartedDate(sd && startDate.valueOf());
    setSearchedEndDate(ed && endDate.valueOf());
    setSearchedUserId(userId);

    const ids = await api;
    const reports = await search({ ids, userId });
    const formated = reports.reduce(
      (prev, { orginal, downline, own, upline, match }) => {
        const matchs = [...prev.matchs, match.matchNo];
        matchs.sort();
        return {
          ...prev,
          orginal: sumAllReport(
            prev.orginal,
            orginal,
            undefined,
            userId || user.id
          ),
          downline: sumAllReport(
            prev.downline,
            downline,
            user.id,
            userId || user.id
          ),
          own: sumAllReport(prev.own, own, undefined, userId || user.id),
          upline: sumAllReport(
            prev.upline,
            upline,
            undefined,
            userId || user.id
          ),
          matchs,
        };
      },
      {
        orginal: [],
        downline: [],
        own: [],
        upline: [],
        matchs: [],
      }
    );

    setReport(formated);
  }

  function onReset() {
    setSearchedMatchNos();
    setSearchedStartedDate();
    setSearchedEndDate();
    setSearchedUserId();
    form.reset({});
  }

  function renderLupsum() {
    if (loading) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Loader paper />
          </Grid>
        </Grid>
      );
    }

    if (!report) {
      return;
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: "center",
                    }}
                  ></TableCell>
                  {isOriginal && (
                    <TableCell
                      colSpan={4}
                      style={{
                        background: "rgba(255, 193, 7, .5)",
                        textAlign: "center",
                      }}
                    >
                      All
                    </TableCell>
                  )}
                  {isDownline && (
                    <TableCell
                      colSpan={4}
                      style={{
                        background: "rgba(76, 175, 80, .5)",
                        textAlign: "center",
                      }}
                    >
                      Downline
                    </TableCell>
                  )}
                  {isOwn && (
                    <TableCell
                      colSpan={5}
                      style={{
                        background: "rgba(33, 150, 243, .5)",
                        textAlign: "center",
                      }}
                    >
                      Own
                    </TableCell>
                  )}
                  {isUpline && (
                    <TableCell
                      colSpan={4}
                      style={{
                        background: "rgba(255, 87, 34, .5)",
                        textAlign: "center",
                      }}
                    >
                      Upline
                    </TableCell>
                  )}
                  <TableCell
                    style={{
                      textAlign: "center",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  {/** All Start */}
                  {isOriginal && (
                    <>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(255, 193, 7, .5)" }}
                      >
                        Turnovers
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(255, 193, 7, .5)" }}
                      >
                        Commission
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(255, 193, 7, .5)" }}
                      >
                        Payout
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(255, 193, 7, .5)" }}
                      >
                        Total
                      </TableCell>
                    </>
                  )}
                  {/** All End */}

                  {/** Downline Start */}
                  {isDownline && (
                    <>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(76, 175, 80, .5)" }}
                      >
                        Turnovers
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(76, 175, 80, .5)" }}
                      >
                        Commission
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(76, 175, 80, .5)" }}
                      >
                        Payout
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(76, 175, 80, .5)" }}
                      >
                        Total
                      </TableCell>
                    </>
                  )}
                  {/** Downline End */}

                  {/** Own Start */}
                  {isOwn && (
                    <>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(33, 150, 243, .5)" }}
                      >
                        Turnovers
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(33, 150, 243, .5)" }}
                      >
                        Commission
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(33, 150, 243, .5)" }}
                      >
                        Earn Comm.
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(33, 150, 243, .5)" }}
                      >
                        Payout
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(33, 150, 243, .5)" }}
                      >
                        Total
                      </TableCell>
                    </>
                  )}
                  {/** Own End */}
                  {/** Upline Start */}
                  {isUpline && (
                    <>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(255, 87, 34, .5)" }}
                      >
                        Turnovers
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(255, 87, 34, .5)" }}
                      >
                        Commission
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(255, 87, 34, .5)" }}
                      >
                        Payout
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ background: "rgba(255, 87, 34, .5)" }}
                      >
                        Total
                      </TableCell>
                    </>
                  )}
                  {/** Upline End */}

                  <TableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    %
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {report &&
                  report.orginal.map((_, index) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {isSubAccRole
                          ? report.orginal[index].userName
                          : report.orginal[index].name}
                      </TableCell>
                      {isOriginal && (
                        <>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(255, 193, 7, .1)" }}
                          >
                            {formatAmount(
                              (report.orginal[index] &&
                                report.orginal[index].turnovers) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(255, 193, 7, .1)" }}
                          >
                            {formatAmount(
                              (report.orginal[index] &&
                                report.orginal[index].commission) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(255, 193, 7, .1)" }}
                          >
                            {formatAmount(
                              (report.orginal[index] &&
                                report.orginal[index].payout) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(255, 193, 7, .1)" }}
                          >
                            {formatAmount(
                              (report.orginal[index] &&
                                report.orginal[index].total) ||
                                0
                            )}
                          </TableCell>
                        </>
                      )}

                      {isDownline && (
                        <>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(76, 175, 80, .1)" }}
                          >
                            {formatAmount(
                              (report.downline[index] &&
                                report.downline[index].turnovers) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(76, 175, 80, .1)" }}
                          >
                            {formatAmount(
                              (report.downline[index] &&
                                report.downline[index].commission) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(76, 175, 80, .1)" }}
                          >
                            {formatAmount(
                              (report.downline[index] &&
                                report.downline[index].payout) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(76, 175, 80, .1)" }}
                          >
                            {formatAmount(
                              (report.downline[index] &&
                                report.downline[index].total) ||
                                0
                            )}
                          </TableCell>
                        </>
                      )}

                      {isOwn && (
                        <>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(33, 150, 243, .1)" }}
                          >
                            {formatAmount(
                              (report.own[index] &&
                                report.own[index].turnovers) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(33, 150, 243, .1)" }}
                          >
                            {formatAmount(
                              (report.own[index] &&
                                report.own[index].commission) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(33, 150, 243, .1)" }}
                          >
                            {formatAmount(
                              (report.own[index] &&
                                report.own[index].earnCommission) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(33, 150, 243, .1)" }}
                          >
                            {formatAmount(
                              (report.own[index] && report.own[index].payout) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(33, 150, 243, .1)" }}
                          >
                            {formatAmount(
                              (report.own[index] && report.own[index].total) ||
                                0
                            )}
                          </TableCell>
                        </>
                      )}

                      {isUpline && (
                        <>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(255, 87, 34, .1)" }}
                          >
                            {formatAmount(
                              (report.upline[index] &&
                                report.upline[index].turnovers) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(255, 87, 34, .1)" }}
                          >
                            {formatAmount(
                              (report.upline[index] &&
                                report.upline[index].commission) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(255, 87, 34, .1)" }}
                          >
                            {formatAmount(
                              (report.upline[index] &&
                                report.upline[index].payout) ||
                                0
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ background: "rgba(255, 87, 34, .1)" }}
                          >
                            {formatAmount(
                              (report.upline[index] &&
                                report.upline[index].total) ||
                                0
                            )}
                          </TableCell>
                        </>
                      )}
                      <TableCell align="center">
                        {(report.downline[index] &&
                          report.downline[index].perc) ||
                          0}
                        %
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }

  function renderSearchContent() {
    return (
      <FormProvider {...form}>
        <Grid item lg={6} xs={12}>
          <Card component="form" onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MatchNoInput />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <StartDateInput />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <EndDateInput />
                </Grid>
                {isMasterRole && (
                  <Grid item xs={12}>
                    <UsernameInput />
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions>
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button color="secondary" onClick={onReset}>
                Reset
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid xs={12} lg={6}>
          {report && !loading && (
            <SummaryCard>
              <Value $primary>
                {report.matchs.length} <Title>match(s)</Title>
              </Value>
              <Title sub>{report.matchs.join(", ")}</Title>
            </SummaryCard>
          )}
        </Grid>
      </FormProvider>
    );
  }

  function renderFilterChips() {
    if (!report) {
      return;
    }

    return (
      <FilterChips
        notAll
        selected={selections}
        items={selections}
        onChange={(filter) => setSelectedFilter(filter)}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      {renderSearchContent()}
      <Grid item xs={12}>
        {renderFilterChips()}
      </Grid>
      <Grid item xs={12}>
        {renderLupsum()}
      </Grid>
    </Grid>
  );

  // return (
  //   <Grid container spacing={2}>
  //     <Grid item xs={12}>
  //       {renderOrginal()}
  //     </Grid>
  //     <Grid item xs={12}>
  //       {renderDownline()}
  //     </Grid>
  //     <Grid item xs={12}>
  //       {renderOwn()}
  //     </Grid>
  //     <Grid item xs={12}>
  //       {renderUpline()}
  //     </Grid>
  //   </Grid>
  // );
}

export default WinLoseDateReport;

// <Grid item xs={12}>
// {renderDownline()}
// </Grid>

// <Grid item xs={12}>
// {renderOwn()}
// </Grid>
