import { useEffect, useState } from "react";

import { API_URL } from "../../constants/EnvConfig";
import { useAuthContext } from "../../context/AuthContext";
import useAuthFetch from "../useAuthFetch";

// import useFetch from "../useFetch";

export const uniqueArray = (array) =>
  array.filter((value, index, self) => self.indexOf(value) === index);

export function getAllDownline(userId, users) {
  const allAgents = users.reduce(
    (prev, user) => ({
      ...prev,
      [user.id]: user,
    }),
    {}
  );

  return innerGetAllDownline(userId, allAgents);
}

export function innerGetAllDownline(agentId, allAgents, downlineOnly) {
  const me = allAgents[agentId];
  const { agentList = [] } = me || {};
  const agentIds = agentList.map(({ id }) => id);
  const formatted = agentIds.reduce(
    (prev, id) => [...prev, ...innerGetAllDownline(id, allAgents)],
    []
  );

  return uniqueArray(
    downlineOnly
      ? [...agentIds, ...formatted]
      : [agentId, ...agentIds, ...formatted]
  );
}

function usePagedUsers() {
  const prefix = `${API_URL}/user/page/downlineIds`;

  const { user: authUser } = useAuthContext();
  const { id: userId, role: userRole } = authUser ?? {};

  const { loading, get } = useAuthFetch({});

  const [users, setUsers] = useState([]);

  async function pageRefresh({ lastKey }) {
    const url = lastKey ? `${prefix}?lastKey=${lastKey}` : prefix;
    return await get({ url });
  }

  async function refreshAll(params) {
    const { users: all = [], lastKey: lk } = params || {};
    const paged = await pageRefresh({ lastKey: lk });
    const { items, lastKey } = paged || {};
    const updated = [...all, ...items];

    if (!lastKey) {
      const filtered =
        userRole === "master" ? updated : getAllDownline(userId, updated);
      setUsers(filtered);
      return filtered;
    }

    return refreshAll({ users: updated, lastKey });
  }

  useEffect(() => {
    refreshAll();
  }, []);

  return { response: users, loading, refresh: refreshAll };
}

export default usePagedUsers;
