import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ChangePassword from "../password/Password";
import UserDetail from "../userDetail/UserDetail";
import AddUser from "../addUser/AddUser";
import SharedLayout from "../components/SharedLayout";
import { useAppContext } from "../context/AppContext";
import Betting from "../betting/Betting";
import Betting2 from "../betting/Betting.Master";
import BettingHistory from "../bettingHistory/History";
import WinHistory from "../bettingHistory/History.Win";
import Home from "../home/Home";
import Matchs2 from "../match/Match.Master";
import Matchs from "../match/Match";
import AddMatch from "../addMatch/AddMatch";
import WinLoseReport from "../report/WinLose";
import BetReport from "../betReport/BetReport";
import MatchDetail from "../matchDetail/MatchDetail";
import { useAuthContext } from "../context/AuthContext";
import { ACCESS, ROLE } from "../constants/Role";
import OddReportDownload from "../betReport/OddReport.Download";
import OddReport from "../betReport/OddReport";
import AddTeam from "../addTeam/AddTeam";
import AddSeason from "../addSeason/AddSeason";
import Season from "../season/Season";
import Teams from "../team/Team";
import TeamDetail from "../teamDetail/TeamDetail";
import SeasonDetail from "../seasonDetail/SeasonDetail";
import MatchOdd from "../match/Match.Odd";
import FightReport from "../fightReport/FightReport";
import SubAccounts from "../subAccount/SubAccount";
import SubAccountDetail from "../subAccountDetail/SubAccountDetail";
import AddSubAccount from "../addSubAccount/AddSubAccount";
import FightMatchs from "../match/Match.Fight";
import WinLoseMatchs from "../match/Match.WinLose";
import Report from "../report/Report";
import Reset from "../resetSubmaster/Reset";
import StrikeMatchs from "../match/Match.Strike";
import WinLoseDateReport from "../report/WinLose.Date";

function Root() {
  const { pageTitle } = useAppContext();
  const { user } = useAuthContext();

  const { role, access } = user ?? {};
  const isAdmin = role === ROLE.admin;
  const isMaster = role === ROLE.master;
  const isAgent = role === ROLE.agent;
  // const isReport = role === ROLE.report;

  const roleAllowed = isAdmin || isMaster || isAgent;

  return (
    <SharedLayout title={pageTitle}>
      <Switch>
        <Route exact path="/user/management/add/:id?">
          <AddUser />
        </Route>
        <Route exact path="/user/management/:id">
          <UserDetail />
        </Route>
        <Route exact path="/user/management/:id/agent">
          <UserDetail agent />
        </Route>
        {(isMaster || isAgent) && (
          <Route exact path="/subaccount">
            <SubAccounts />
          </Route>
        )}

        {(isMaster || isAgent) && (
          <Route exact path="/subaccount/add">
            <AddSubAccount />
          </Route>
        )}
        {(isMaster || isAgent) && (
          <Route exact path="/subaccount/:id">
            <SubAccountDetail />
          </Route>
        )}
        {isMaster && (
          <Route exact path="/match/add">
            <AddMatch />
          </Route>
        )}
        {isMaster && (
          <Route exact path="/match/:id">
            <MatchDetail />
          </Route>
        )}
        {isMaster && (
          <Route exact path="/match">
            <Matchs2 />
          </Route>
        )}
        {isMaster && (
          <Route exact path="/team/:seasonId/add">
            <AddTeam />
          </Route>
        )}
        {isMaster && (
          <Route exact path="/team/:id">
            <TeamDetail />
          </Route>
        )}
        {isMaster && (
          <Route exact path="/team">
            <Teams />
          </Route>
        )}
        {isMaster && (
          <Route exact path="/season/add">
            <AddSeason />
          </Route>
        )}
        {isMaster && (
          <Route exact path="/season/:id">
            <SeasonDetail />
          </Route>
        )}
        {isMaster && (
          <Route exact path="/season">
            <Season />
          </Route>
        )}
        {(access & ACCESS.ChangePassword || roleAllowed) && (
          <Route exact path="/user/password">
            <ChangePassword />
          </Route>
        )}
        {(access & ACCESS.Bet || roleAllowed) && (
          <Route exact path="/betting/history">
            <BettingHistory />
          </Route>
        )}
        {(access & ACCESS.Bet || roleAllowed) && (
          <Route exact path="/betting">
            <Home />
          </Route>
        )}
        {(access & ACCESS.Bet || roleAllowed) && (
          <Route exact path="/betting/:id">
            {isMaster ? <Betting2 /> : <Betting />}
          </Route>
        )}
        {(access & ACCESS.WinLoseReport || roleAllowed) && (
          <Route exact path="/report/bet">
            <Matchs />
          </Route>
        )}
        {(access & ACCESS.WinLoseReport || roleAllowed) && (
          <Route exact path="/report/bet/:id">
            <BetReport />
          </Route>
        )}

        {(access & ACCESS.OddReport || roleAllowed) && (
          <Route exact path="/plate">
            <MatchOdd />
          </Route>
        )}
        {(access & ACCESS.OddReport || roleAllowed) && (
          <Route exact path="/plate/:id">
            <OddReport />
          </Route>
        )}
        {(access & ACCESS.OddReport || roleAllowed) && (
          <Route exact path="/plate/:id/download">
            <OddReportDownload />
          </Route>
        )}

        {(access & ACCESS.WinLoseReport || roleAllowed) && (
          <Route exact path="/report/winlose">
            <WinLoseDateReport />
          </Route>
        )}

        {(access & ACCESS.FightReport || roleAllowed) && (
          <Route exact path="/report/fight">
            <FightMatchs />
          </Route>
        )}
        {(access & ACCESS.FightReport || roleAllowed) && (
          <Route exact path="/report/fight/:id">
            <FightReport />
          </Route>
        )}
        {(access & ACCESS.StrikeReport || roleAllowed) && (
          <Route exact path="/report/strike">
            <StrikeMatchs />
          </Route>
        )}
        {(access & ACCESS.StrikeReport || roleAllowed) && (
          <Route exact path="/report/strike/:matchId">
            <WinHistory />
          </Route>
        )}
        {(access & ACCESS.MatchReport || roleAllowed) && (
          <Route exact path="/kk/report">
            <Report />
          </Route>
        )}
        {(access & ACCESS.BetReport || roleAllowed) && (
          <Route exact path="/report/bet">
            <BetReport />
          </Route>
        )}

        <Route exact path="/submaster/reset">
          <Reset />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Redirect to="/" />
      </Switch>
    </SharedLayout>
  );
}

export default Root;
