import LibAutocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";
// import TextField from "./TextField";
import TextField from "@material-ui/core/TextField";

const filter = createFilterOptions();

const AppAutocomplete = styled(LibAutocomplete)({
  // marginBottom: 16,
});

const Autocomplete = ({
  label,
  name,
  rules,
  defaultValue = "",
  disabled,
  items = [],

  onBlur,
  margin,

  allowAdd,

  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  let selectedValue;

  if (typeof value === "string") {
    const selectedText = items?.find((s) => value === s.value)?.text;
    selectedValue = {
      value,
      text: selectedText,
    };
  }

  const inputProps = {
    name: "disabled",
    label,
    disabled,
    margin,
    helperText: message,
    error,

    onBlur,

    variant: "outlined",
    fullWidth: true,
  };

  const props = {
    ...rest,
    value: selectedValue ?? value,
    onChange: (e, newValue) => onChange(newValue),
    options: items,
    openOnFocus: true,
    getOptionLabel: ({ text }) => text || "",
    getOptionSelected: ({ value }, compare) => value === compare.value,
    renderInput: (params) => <TextField {...params} {...inputProps} />,
    filterOptions: allowAdd
      ? (options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push(params.inputValue);
          }

          return filtered;
        }
      : undefined,
  };

  return <AppAutocomplete {...props} />;
};

export default Autocomplete;
