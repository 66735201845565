import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useTeam({ id = "", auto } = {}) {
  const prefix = `${API_URL}/team`;
  const url = id ? `${prefix}/${id}` : prefix;

  const { response, loading, get, post, put, del } = useAuthFetch(
    auto ? { url, cache: true } : { cache: true}
  );

  function refresh() {
    return get({ url });
  }

  function refreshById(tId) {
    if (!tId) {
      return;
    }

    return get({ url: `${prefix}/${tId}` });
  }

  function create(body) {
    return post({
      body,
      url,
    });
  }

  function update(body) {
    return put({
      body,
      url,
    });
  }

  function remove(id) {
    return del({
      url: `${prefix}/${id}`,
    });
  }

  return { response, loading, refresh, refreshById, create, update, remove };
}

export default useTeam;
