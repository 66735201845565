export const PASSWORD_POLICY = {
  moreThan8Characters: /(.{8,})/,
  uppercase: /[A-Z]/,
  lowercase: /[a-z]/,
  number: /\d/,
  symbol: /(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])/,
  all: /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  pattern: {
    value: /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    message: "Password too weak",
  },
};

export const REQUIRED_RULE = {
  required: "This is a required field",
};

export const EMAIL_RULE = {
  required: "Email is a required field",
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Invalid email format",
  },
};

export const ALPHABET_RULE = {
  pattern: {
    value: /^[A-Za-z ]+$/,
    message: "Valid characters include A-Z, a-z and space",
  },
};

export const ALPHA_NUMERIC_RULE = {
  pattern: {
    value: /^[a-z0-9!@$_-]+$/i,
    message: "Valid characters include A-Z, a-z, 0-9, !, @, $, _ and -",
  },
};

export const POSTCODE_RULE = {
  pattern: {
    value: /^[0-9]{5}$/,
    message: "Invalid postcode.",
  },
};

export const MAX_RULE = (value) => ({
  max: { value, message: `Exceed maximum limit: ${value}` },
});

export const MIN_RULE = (value) => ({
  min: { value, message: `Less than minimum: ${value}` },
});

export const strongPolicyPassword = (password) => {
  const regex = new RegExp(PASSWORD_POLICY.all);
  return regex.test(password);
};

export default {};
