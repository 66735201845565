import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useSeason({ id = "", auto } = {}) {
  const prefix = `${API_URL}/season`;
  const url = id ? `${prefix}/${id}` : prefix;

  const { response, loading, get, post, put } = useAuthFetch(
    auto ? { url } : {}
  );

  function refresh() {
    return get({ url });
  }

  function refreshById(tId) {
    if (!tId) {
      return;
    }

    return get({ url: `${prefix}/${tId}` });
  }

  function create(body) {
    return post({
      body,
      url,
    });
  }

  function update(body) {
    return put({
      body,
      url,
    });
  }

  function getTeams(id) {
    return get({ url: `${prefix}/${id}/team` });
  }

  return { response, loading, refresh, refreshById, create, update, getTeams };
}

export default useSeason;
