import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import GamesIcon from "@material-ui/icons/Games";
import HistoryIcon from "@material-ui/icons/History";
import HomeIcon from "@material-ui/icons/Home";
import LockIcon from "@material-ui/icons/Lock";
import PeopleIcon from "@material-ui/icons/People";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ACCESS, ROLE } from "../constants/Role";
import { useAuthContext } from "../context/AuthContext";
import EventNoteIcon from "@material-ui/icons/EventNote";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import TableChartIcon from "@material-ui/icons/TableChart";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import SportsIcon from "@material-ui/icons/Sports";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";

export const DRAWER_WIDTH = 240;

const ToolbarIcon = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0 8px",
  ...theme.mixins.toolbar,
}));

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
}));

function AppDrawer({ open, setOpen }) {
  const { user } = useAuthContext();

  const { access, role, id } = user ?? {};

  const isAdmin = role === ROLE.admin;
  const isMaster = role === ROLE.master;
  const isAgent = role === ROLE.agent;
  const isReport = role === ROLE.report;
  const isSubAccount = role === ROLE.subaccount;

  const classes = useStyles();
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getMenus = () => {
    if (isAdmin) {
      return {
        userMenus: [
          {
            icon: LockIcon,
            text: "Change Password",
            url: "/user/password",
          },
          {
            icon: PeopleIcon,
            text: "User Management",
            url: `/user/management/${id}`,
          },
        ],
        gameMenus: [
          {
            icon: HistoryIcon,
            text: "Betting History",
            url: "/betting/history",
          },
        ],
        // reportMenus: [
        //   {
        //     icon: AssessmentIcon,
        //     text: "Match Report",
        //     url: "/report/winlose",
        //   },
        // ],
      };
    }

    if (isMaster) {
      return {
        userMenus: [
          {
            icon: HomeIcon,
            text: "Home",
            url: "",
            notAdmin: true,
          },
          {
            icon: LockIcon,
            text: "Change Password",
            url: "/user/password",
          },
          {
            icon: PeopleIcon,
            text: "User Management",
            url: `/user/management/${id}`,
          },
          {
            icon: PeopleOutlineIcon,
            text: "Sub Account",
            url: `/subaccount`,
          },
          {
            icon: RotateLeftIcon,
            text: "Reset",
            url: `/submaster/reset`,
          },
        ],
        gameMenus: [
          {
            icon: GamesIcon,
            text: "Betting",
            url: "/betting",
            notAdmin: true,
          },
          {
            icon: HistoryIcon,
            text: "Betting History",
            url: "/betting/history",
          },
          {
            icon: SportsSoccerIcon,
            text: "Season",
            url: "/season",
          },
          {
            icon: SportsIcon,
            text: "Match",
            url: "/match",
          },
          {
            icon: EventNoteIcon,
            text: "Plate",
            url: "/plate",
            notAdmin: true,
          },
        ],
        reportMenus: [
          {
            icon: AssessmentIcon,
            text: "Win/Lose Report",
            url: "/report/winlose",
          },
          {
            icon: BubbleChartIcon,
            text: "Total Bet Report",
            url: "/report/bet",
          },
          {
            icon: TableChartIcon,
            text: "Fight Report",
            url: "/report/fight",
          },
          {
            icon: StrikethroughSIcon,
            text: "Strike Report",
            url: "/report/strike",
          },
          {
            icon: TableChartIcon,
            text: "Match Report (Old)",
            url: "/kk/report",
          },
        ],
      };
    }

    if (isAgent) {
      return {
        userMenus: [
          {
            icon: HomeIcon,
            text: "Home",
            url: "",
            notAdmin: true,
          },
          {
            icon: LockIcon,
            text: "Change Password",
            url: "/user/password",
          },
          {
            icon: PeopleIcon,
            text: "User Management",
            url: `/user/management/${id}`,
          },
          {
            icon: PeopleOutlineIcon,
            text: "Sub Account",
            url: `/subaccount`,
          },
        ],
        gameMenus: [
          {
            icon: GamesIcon,
            text: "Betting",
            url: "/betting",
            notAdmin: true,
          },
          {
            icon: HistoryIcon,
            text: "Betting History",
            url: "/betting/history",
          },
          {
            icon: EventNoteIcon,
            text: "Plate",
            url: "/plate",
            notAdmin: true,
          },
        ],
        reportMenus: [
          {
            icon: AssessmentIcon,
            text: "Win/Lose Report",
            url: "/report/winlose",
          },
          {
            icon: BubbleChartIcon,
            text: "Total Bet Report",
            url: "/report/bet",
          },
          {
            icon: TableChartIcon,
            text: "Fight Report",
            url: "/report/fight",
          },
          {
            icon: StrikethroughSIcon,
            text: "Strike Report",
            url: "/report/strike",
          },
          {
            icon: TableChartIcon,
            text: "Match Report (Old)",
            url: "/kk/report",
          },
        ],
      };
    }

    if (isSubAccount) {
      return {
        userMenus: [
          access & ACCESS.Bet && {
            icon: HomeIcon,
            text: "Home",
            url: "",
            notAdmin: true,
          },
          access &
            ACCESS.ChangePassword && {
              icon: LockIcon,
              text: "Change Password",
              url: "/user/password",
            },
        ],
        gameMenus: [
          access & ACCESS.Bet && {
            icon: GamesIcon,
            text: "Betting",
            url: "/betting",
            notAdmin: true,
          },
          access & ACCESS.Bet && {
            icon: HistoryIcon,
            text: "Betting History",
            url: "/betting/history",
          },
        ],
        reportMenus: [
          access & ACCESS.WinLoseReport && {
            icon: AssessmentIcon,
            text: "Win/Lose Report",
            url: "/report/winlose",
          },
          access & ACCESS.BetReport && {
            icon: TableChartIcon,
            text: "Total Bet Report",
            url: "/report/bet",
          },
          access & ACCESS.FightReport && {
            icon: BubbleChartIcon,
            text: "Fight Report",
            url: "/report/fight",
          },
          access & ACCESS.OddReport && {
            icon: EventNoteIcon,
            text: "Plate",
            url: "/plate",
            notAdmin: true,
          },
          access & ACCESS.StrikeReport && {
            icon: StrikethroughSIcon,
            text: "Strike Report",
            url: "/report/strike",
          },
          access & ACCESS.MatchReport && {
            icon: TableChartIcon,
            text: "Match Report (Old)",
            url: "/kk/report",
          },
        ],
      };
    }

    if (isReport) {
      return {
        userMenus: [],
        gameMenus: [
          {
            icon: AssessmentIcon,
            text: "Win/Lose Report",
            url: "/report/winlose",
          },
          // {
          //   icon: EventNoteIcon,
          //   text: "Win/Lose Report",
          //   url: "/report/bet",
          // },
        ],
      };
    }
  };

  const renderItem = ({ icon: Icon, text, url }) => {
    return (
      <ListItem button component={Link} to={url} onClick={handleDrawerClose}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  };

  const {
    userMenus: um = [],
    gameMenus: gm = [],
    reportMenus: rm = [],
  } = getMenus();
  const userMenus = um.filter((s) => !!s);
  const gameMenus = gm.filter((s) => !!s);
  const reportMenus = rm.filter((s) => !!s);
  return (
    <Drawer
      // variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      anchor="left"
      open={open}
    >
      <ToolbarIcon>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </ToolbarIcon>
      <Divider />

      {userMenus.length !== 0 && (
        <List>
          <ListSubheader inset>User</ListSubheader>
          {userMenus.map(renderItem)}
        </List>
      )}
      {gameMenus.length !== 0 && (
        <List>
          <ListSubheader inset>Gaming</ListSubheader>
          {gameMenus.map(renderItem)}
        </List>
      )}

      {reportMenus.length !== 0 && (
        <List>
          <ListSubheader inset>Report</ListSubheader>
          {reportMenus.map(renderItem)}
        </List>
      )}
    </Drawer>
  );
}

export default AppDrawer;
