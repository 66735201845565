import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
// import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import DatePicker from "../components/Datepicker";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { REQUIRED_RULE } from "../helpers/Form";
import { numberWithCommas } from "../helpers/String";
import useDateReports from "../hooks/api/useDateReports";
import useAdminReport from "../hooks/api/useReport.Admin";
import useReports from "../hooks/api/useReports";
import useDownlineWinLoseReport from "../hooks/api/useWinLoseReport.Downline";
import useOriginalWinLoseReport from "../hooks/api/useWinLoseReport.Orginal";
import useOverallWinLoseReport from "../hooks/api/useWinLoseReport.Overall";
import useOwnWinLoseReport from "../hooks/api/useWinLoseReport.Own";
import useUplineWinLoseReport from "../hooks/api/useWinLoseReport.Upline";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";
import useQueryString from "../hooks/useQuerystring";

const Value = styled(Typography).attrs({
  variant: "h3",
})(({ $error, $primary }) => ({
  marginBottom: 16,
  color: $primary ? "rgba(255, 193, 7, .5)" : $error ? red[300] : green[500],
  wordBreak: "break-word",
  textAlign: "center",
}));

const Title = styled(Typography).attrs({
  variant: "h6",
  // color: "textSecondary",
})({
  marginTop: 10,
  marginBottom: 20,
  textAlign: "center",
  // color: "#fff",
});

const SummaryCard = styled(Paper)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: 32,
  paddingBottom: 16,
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 155,
  height: "100%",
});

function Report() {
  const { setPageTitle } = useAppContext();
  const { id } = useParams();

  const { response: report = {}, loading } = useOverallWinLoseReport({
    id,
  });

  const { match } = report || {};
  const { matchNo, score } = match || {};

  useEffect(() => {
    if (!matchNo) {
      setPageTitle("Win/Lose Report");
      return;
    }

    setPageTitle(`Win/Lose Report - ${matchNo}`);
  }, [matchNo]);

  function formatAmount(amount = 0) {
    if (amount >= 0) {
      return `${numberWithCommas(amount.toFixed(2))}`;
    }

    return `-${numberWithCommas(Math.abs(amount.toFixed(2)))}`;
  }

  function renderLupsum() {
    if (loading) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Loader paper />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {score}
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    style={{
                      background: "rgba(255, 193, 7, .5)",
                      textAlign: "center",
                    }}
                  >
                    All
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    style={{
                      background: "rgba(76, 175, 80, .5)",
                      textAlign: "center",
                    }}
                  >
                    Downline
                  </TableCell>
                  <TableCell
                    colSpan={5}
                    style={{
                      background: "rgba(33, 150, 243, .5)",
                      textAlign: "center",
                    }}
                  >
                    Own
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    style={{
                      background: "rgba(255, 87, 34, .5)",
                      textAlign: "center",
                    }}
                  >
                    Upline
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  {/** All Start */}
                  <TableCell
                    align="right"
                    style={{ background: "rgba(255, 193, 7, .5)" }}
                  >
                    Turnovers
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(255, 193, 7, .5)" }}
                  >
                    Commission
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(255, 193, 7, .5)" }}
                  >
                    Payout
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(255, 193, 7, .5)" }}
                  >
                    Total
                  </TableCell>
                  {/** All End */}

                  {/** Downline Start */}
                  <TableCell
                    align="right"
                    style={{ background: "rgba(76, 175, 80, .5)" }}
                  >
                    Turnovers
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(76, 175, 80, .5)" }}
                  >
                    Commission
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(76, 175, 80, .5)" }}
                  >
                    Payout
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(76, 175, 80, .5)" }}
                  >
                    Total
                  </TableCell>
                  {/** Downline End */}

                  {/** Own Start */}
                  <TableCell
                    align="right"
                    style={{ background: "rgba(33, 150, 243, .5)" }}
                  >
                    Turnovers
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(33, 150, 243, .5)" }}
                  >
                    Commission
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(33, 150, 243, .5)" }}
                  >
                    Earn Comm.
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(33, 150, 243, .5)" }}
                  >
                    Payout
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(33, 150, 243, .5)" }}
                  >
                    Total
                  </TableCell>
                  {/** Own End */}
                  {/** Upline Start */}
                  <TableCell
                    align="right"
                    style={{ background: "rgba(255, 87, 34, .5)" }}
                  >
                    Turnovers
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(255, 87, 34, .5)" }}
                  >
                    Commission
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(255, 87, 34, .5)" }}
                  >
                    Payout
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ background: "rgba(255, 87, 34, .5)" }}
                  >
                    Total
                  </TableCell>
                  {/** Upline End */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow></TableRow>
                {report.orginal.map((_, index) => (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {report.orginal[index].userName}
                    </TableCell>

                    <TableCell
                      align="right"
                      style={{ background: "rgba(255, 193, 7, .1)" }}
                    >
                      {formatAmount(
                        (report.orginal[index] &&
                          report.orginal[index].turnovers) ||
                          0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(255, 193, 7, .1)" }}
                    >
                      {formatAmount(
                        (report.orginal[index] &&
                          report.orginal[index].commission) ||
                          0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(255, 193, 7, .1)" }}
                    >
                      {formatAmount(
                        (report.orginal[index] &&
                          report.orginal[index].payout) ||
                          0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(255, 193, 7, .1)" }}
                    >
                      {formatAmount(
                        (report.orginal[index] &&
                          report.orginal[index].total) ||
                          0
                      )}
                    </TableCell>

                    <TableCell
                      align="right"
                      style={{ background: "rgba(76, 175, 80, .1)" }}
                    >
                      {formatAmount(
                        (report.downline[index] &&
                          report.downline[index].turnovers) ||
                          0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(76, 175, 80, .1)" }}
                    >
                      {formatAmount(
                        (report.downline[index] &&
                          report.downline[index].commission) ||
                          0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(76, 175, 80, .1)" }}
                    >
                      {formatAmount(
                        (report.downline[index] &&
                          report.downline[index].payout) ||
                          0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(76, 175, 80, .1)" }}
                    >
                      {formatAmount(
                        (report.downline[index] &&
                          report.downline[index].total) ||
                          0
                      )}
                    </TableCell>

                    <TableCell
                      align="right"
                      style={{ background: "rgba(33, 150, 243, .1)" }}
                    >
                      {formatAmount(
                        (report.own[index] && report.own[index].turnovers) || 0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(33, 150, 243, .1)" }}
                    >
                      {formatAmount(
                        (report.own[index] && report.own[index].commission) || 0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(33, 150, 243, .1)" }}
                    >
                      {formatAmount(
                        (report.own[index] &&
                          report.own[index].earnCommission) ||
                          0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(33, 150, 243, .1)" }}
                    >
                      {formatAmount(
                        (report.own[index] && report.own[index].payout) || 0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(33, 150, 243, .1)" }}
                    >
                      {formatAmount(
                        (report.own[index] && report.own[index].total) || 0
                      )}
                    </TableCell>

                    <TableCell
                      align="right"
                      style={{ background: "rgba(255, 87, 34, .1)" }}
                    >
                      {formatAmount(
                        (report.upline[index] &&
                          report.upline[index].turnovers) ||
                          0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(255, 87, 34, .1)" }}
                    >
                      {formatAmount(
                        (report.upline[index] &&
                          report.upline[index].commission) ||
                          0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(255, 87, 34, .1)" }}
                    >
                      {formatAmount(
                        (report.upline[index] && report.upline[index].payout) ||
                          0
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ background: "rgba(255, 87, 34, .1)" }}
                    >
                      {formatAmount(
                        (report.upline[index] && report.upline[index].total) ||
                          0
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {renderLupsum()}
      </Grid>
    </Grid>
  );

  // return (
  //   <Grid container spacing={2}>
  //     <Grid item xs={12}>
  //       {renderOrginal()}
  //     </Grid>
  //     <Grid item xs={12}>
  //       {renderDownline()}
  //     </Grid>
  //     <Grid item xs={12}>
  //       {renderOwn()}
  //     </Grid>
  //     <Grid item xs={12}>
  //       {renderUpline()}
  //     </Grid>
  //   </Grid>
  // );
}

export default Report;

// <Grid item xs={12}>
// {renderDownline()}
// </Grid>

// <Grid item xs={12}>
// {renderOwn()}
// </Grid>
