import LibContainer from "@material-ui/core/Container";
import React, { useState } from "react";
import styled from "styled-components";
import Drawer from "../components/Drawer";
import NavBar from "./NavBar";

export const Root = styled.div({
  display: "flex",
  background: "linear-gradient(to bottom, rgb(116,18,46), rgb(180,44,91))",
});

const AppBarFlex = styled.div(({ theme }) => theme.mixins.toolbar);

export const Main = styled.main({
  flexGrow: 1,
  height: "100vh",
  overflow: "auto",
});

export const Container = styled(LibContainer).attrs({
  maxWidth: "lg",
})(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

function SharedLayout({ title, children }) {
  const [open, setOpen] = useState(false);

  const drawerProps = {
    open,
    setOpen,
  };

  return (
    <Root>
      <NavBar {...drawerProps} title={title} />
      <Drawer {...drawerProps} />
      <Main>
        <AppBarFlex />
        <Container>{children}</Container>
      </Main>
    </Root>
  );
}

export default SharedLayout;
