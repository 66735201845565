import { useState } from "react";
import { API_URL } from "../../constants/EnvConfig";
import { chunkArray } from "../../helpers/Common";
import useAuthFetch from "../useAuthFetch";

function useOverallWinLoseReportList() {
  const { get } = useAuthFetch({});

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState();

  async function search({ ids, userId }) {
    setLoading(true);
    setReports([]);

    const chunkedIds = chunkArray(ids, 8);

    let arr = [];
    for (const list of chunkedIds) {
      const partial = await Promise.all(
        list.map(async (id) => {
          try {
            const url = `${API_URL}/report/winlose/${id}/overall?userId=${
              userId || ""
            }`;
            return await get({ url });
          } catch (ex) {
            console.log("failed", id);
          }
        })
      );
      arr = [...arr, ...partial].filter((s) => !!s);
    }

    setReports(arr);
    setLoading(false);
    return arr;
  }

  return { response: reports, loading, search };
}

export default useOverallWinLoseReportList;
