import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import IconButton from "@material-ui/core/IconButton";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import Loader from "../components/Loader";
import Table from "../components/Table";
import { formatStandard } from "../helpers/DateTime";
import useSubAccount from "../hooks/api/useSubAccount";
import Toggle from "./Toggle";

// import Credits from "./Credit";
// import Filter from "./Filter";
// import Toggle from "./Toggle";

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: status === "inactive" ? red[900] : green[500],
  marginRight: theme.spacing(1),
}));

const Name = styled.span(({ head, theme }) => ({
  flex: 1,
  paddingLeft: head && 10 + theme.spacing(1),
  paddingRight: theme.spacing(5),
}));

const OffIcon = styled(ToggleOffIcon)(({ active }) => ({
  color: !active ? red[900] : green[500],
}));

const OnIcon = styled(ToggleOnIcon)(({ active }) => ({
  color: !active ? red[900] : green[500],
}));
function Users({ id, showUpdate }) {
  const history = useHistory();
  const [toggleItem, setToggleItem] = useState();

  const {
    response: subAccounts,
    loading,
    refresh,
  } = useSubAccount({ auto: true });

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Username",
      custom: renderNameAndAction,
      customHeader: renderNameAndActionHeader,
    },
    { id: "name", numeric: false, label: "Full Name" },
    {
      id: "updatedAt",
      numeric: false,
      label: "Update At",
      format: (item) => formatStandard(item.updatedAt),
    },
  ];

  const tableProps = {
    headCells,
    items: subAccounts,
    rowsPerPage: 10,
    title: "Sub Accounts",
    uniqueId: "username",
    defaultSort: "updatedAt",
    onAdd: () => {
      history.push(`/subaccount/add`);
    },
    onSelect: ({ id }) => {
      history.push(`/subaccount/${id}`);
    },
  };

  function renderNameAndActionHeader(label) {
    return (
      <StatusContent>
        <Name head>{label}</Name>
      </StatusContent>
    );
  }

  function renderNameAndAction(item) {
    const { id, status } = item;
    const active = status === "active";
    return (
      <StatusContent>
        <Dot status={status} />
        <Name>{id}</Name>
        <IconButton
          title={active ? "Disable User" : "Enable User"}
          size="small"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setToggleItem(item);
          }}
        >
          {active ? <OnIcon active={active} /> : <OffIcon active={active} />}
        </IconButton>
      </StatusContent>
    );
  }

  return (
    <>
      {loading ? <Loader paper /> : <Table {...tableProps} />}
      {/* <Filter {...filterProps} /> */}

      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => refresh()}
      />
    </>
  );
}

export default Users;
