import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import IconButton from "@material-ui/core/IconButton";
import PlusOneIcon from "@material-ui/icons/PlusOne";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { formatStandard } from "../helpers/DateTime";
import { nFormatter } from "../helpers/Number";
import useAgents from "../hooks/api/useAgents";
import useUser from "../hooks/api/useUser";
import useMount from "../hooks/useMount";
import Credits from "./Credit";
import Filter from "./Filter";
import Toggle from "./Toggle";

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: status === "inactive" ? red[900] : green[500],
  marginRight: theme.spacing(1),
}));

const Name = styled.span(({ head, theme }) => ({
  flex: 1,
  paddingLeft: head && 10 + theme.spacing(1),
  paddingRight: theme.spacing(5),
}));

const OffIcon = styled(ToggleOffIcon)(({ active }) => ({
  color: !active ? red[900] : green[500],
}));

const OnIcon = styled(ToggleOnIcon)(({ active }) => ({
  color: !active ? red[900] : green[500],
}));
function Users({ id, showUpdate }) {
  const { setPageTitle } = useAppContext();

  const history = useHistory();
  const [showFilter, setShowFilter] = useState();
  const [toggleItem, setToggleItem] = useState();
  const [addCreditItem, setAddCreditItem] = useState();

  const fromDetail = !!id;

  const {
    response: users,
    loading: userLoading,
    refresh: userRefresh,
    search: userSearch,
  } = useUser({ auto: !fromDetail });
  const {
    response: agents,
    loading: agentLoading,
    refresh: agentRefresh,
    search: agentSearch,
  } = useAgents({ auto: fromDetail, id });

  const response = fromDetail ? agents : users;
  const loading = fromDetail ? agentLoading : userLoading;
  const refresh = () => {
    agentRefresh();
    userRefresh();
  };
  const search = fromDetail ? agentSearch : userSearch;

  useEffect(() => {
    if (id) {
      setPageTitle(`User Management - ${id}`);
    } else {
      setPageTitle("User Management");
    }
  }, [id]);

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Username",
      custom: renderNameAndAction,
      customHeader: renderNameAndActionHeader,
    },
    { id: "name", numeric: false, label: "Full Name" },
    {
      id: "commission",
      numeric: true,
      label: "Commission",
    },
    {
      id: "fight",
      numeric: true,
      label: "Fight",
    },
    {
      id: "fightLimit",
      numeric: true,
      label: "Fight Limit",
      format: ({ fightLimit, maxFightLimit }) =>
        `${nFormatter(fightLimit)}/${nFormatter(maxFightLimit)}`,
    },
    {
      id: "credit",
      numeric: true,
      label: "Credit",
    },
    {
      id: "updatedAt",
      numeric: false,
      label: "Update At",
      format: (item) => formatStandard(item.updatedAt),
    },
  ];

  const creditProps = {
    onClose: () => setAddCreditItem(),
    onAddCredit: refresh,
    item: addCreditItem,
    open: !!addCreditItem,
    loadCredit: true,
  };

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async (data) => {
      setShowFilter();
      if (!data) {
        await refresh();
      } else {
        await search(data);
      }
    },
  };

  const tableProps = {
    headCells,
    items: response,
    rowsPerPage: 10,
    title: "Direct Agents",
    uniqueId: "id",
    defaultSort: "id",
    onAdd: () => {
      history.push(`/user/management/add${id ? `/${id}` : ""}`);
    },
    onFilter: () => {
      setShowFilter(true);
    },
    onSelect: ({ id }) => {
      history.push(`/user/management/${id}${fromDetail ? "/agent" : ""}`);
    },
  };

  const subAgentProps = {
    onAdd: undefined,
  };

  function renderNameAndActionHeader(label) {
    return (
      <StatusContent>
        <Name head>{label}</Name>
      </StatusContent>
    );
  }

  function renderNameAndAction(item) {
    const { id, status, role } = item;
    const active = status === "active";
    const isAllowAddCredit = role === ROLE.agent || role === ROLE.master;
    return (
      <StatusContent>
        <Dot status={status} />
        <Name>{id}</Name>
        {showUpdate && active && isAllowAddCredit && (
          <IconButton
            title="Add Credit"
            color="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setAddCreditItem(item);
            }}
          >
            <PlusOneIcon />
          </IconButton>
        )}
        {showUpdate && (
          <IconButton
            title={active ? "Disable User" : "Enable User"}
            size="small"
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setToggleItem(item);
            }}
          >
            {active ? <OnIcon active={active} /> : <OffIcon active={active} />}
          </IconButton>
        )}
      </StatusContent>
    );
  }

  const table1Props = showUpdate
    ? tableProps
    : { ...tableProps, ...subAgentProps };
  return (
    <>
      {loading ? <Loader paper /> : <Table {...table1Props} />}
      <Filter {...filterProps} />
      <Credits {...creditProps} />
      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => refresh()}
      />
    </>
  );
}

export default Users;
