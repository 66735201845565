export const HOME_ODS = [
    '1-0',
    '2-0',
    '2-1',
    '3-0',
    '3-1',
    '3-2',
    '4-0',
    '4-1',
    '4-2',
    '4-3',
  ];
  export const AWAY_ODS = [
    '0-1',
    '0-2',
    '1-2',
    '0-3',
    '1-3',
    '2-3',
    '0-4',
    '1-4',
    '2-4',
    '3-4',
  ];
  export const DRAW_ODS = ['0-0', '1-1', '2-2', '3-3', '4-4'];
  
  export const ALL_ODDS = [...HOME_ODS, ...AWAY_ODS, ...DRAW_ODS];
  
  export const compareMatchScore = (reportScore, matchScore) => {
    const prevScore = reportScore.replace(/ /g, '');
    const nextScore = matchScore.replace(/ /g, '');
  
    const realScore = ALL_ODDS.includes(nextScore) ? nextScore : 'AOS';
    // if match
    return prevScore === realScore;
  };
  