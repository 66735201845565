import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const defaultTheme = {
  palette: {
    // type: "dark",
    primary: {
      main: "rgb(209,33,91)",
    },
    secondary: {
      main: "rgb(249,177,78)",
    },
    error: {
      main: red.A400,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#fff",
        color: "#000",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 20,
      },
    },
    //   MuiDropzoneArea: {
    //     root: {
    //       minHeight: 120,
    //       paddingTop: 16,
    //       paddingBottom: 16,
    //       borderWidth: 1,
    //       borderColor: "rgba(255, 255, 255, 0.23)",
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "center",
    //     },
    //     text: {
    //       fontSize: "1rem",
    //       color: "rgba(255, 255, 255, 0.7)",
    //       marginTop: 0,
    //       marginBottom: -36,
    //     },
    //     icon: {
    //       marginTop: -36,
    //       width: 32,
    //       height: 32,
    //       color: "rgba(255, 255, 255, 0.7)",
    //     },
    //   },
  },
};

export const darkTheme = createMuiTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    type: "dark",
  },
});

// A custom theme for this app
const theme = createMuiTheme(defaultTheme);

export default theme;
