import { Button } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import LibToolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ROLE } from "../constants/Role";
import { useAuthContext } from "../context/AuthContext";
import useUser from "../hooks/api/useUser";

const Toolbar = styled(LibToolbar)({
  paddingRight: 24,
});

const MenuButton = styled(IconButton).attrs({
  edge: "start",
  color: "inherit",
  ariaLabel: "open drawer",
})(({ open }) => ({
  marginRight: 36,
  display: open && "none",
}));

const MainTitle = styled(Typography).attrs({
  component: "h1",
  variant: "h6",
  color: "inherit",
  noWrap: true,
})({
  flexGrow: 1,
});

// const Avatar = styled(LibAvatar)(({ theme }) => ({
//   color: theme.palette.getContrastText(theme.palette.secondary.main),
//   backgroundColor: theme.palette.secondary.main,
// }));

// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//   },
//   appBarShift: {
//     marginLeft: DRAWER_WIDTH,
//     width: `calc(100% - ${DRAWER_WIDTH}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
// }));

function NavBar({ title, setOpen }) {
  const { signOut, user } = useAuthContext();
  const { role, id } = user ?? {};

  const history = useHistory();

  const isAdminRole = role === ROLE.admin || role === ROLE.subaccount;

  const showCredit = !isAdminRole;

  const { loading, response, refresh } = useUser({
    id: "me",
    auto: showCredit,
  });
  const { credit } = response ?? {};
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  function innerSignOut() {
    history.replace("/");
    signOut();
  }

  return (
    <AppBar position="absolute">
      <Toolbar>
        <MenuButton onClick={handleDrawerOpen}>
          <MenuIcon />
        </MenuButton>
        <MainTitle>{title}</MainTitle>

        {showCredit ? (
          <Button color="inherit" onClick={() => refresh()}>
            {loading ? "Loading..." : `${id}: $ ${credit || 0}`}
          </Button>
        ) : (
          <Button color="inherit">{id}</Button>
        )}
        <IconButton color="inherit" onClick={() => innerSignOut()}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
