import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useController, useFormContext } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

function Checkboxs({ items = [], label, rules, defaultValue, name }) {
  const { control } = useFormContext();
  const {
    field: { ref: inputRef, onChange, value = [] },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const classes = useStyles();

  const handleChange = (event) => {
    const { checked, value: selectedIndex } = event.target;
    const selected = items[selectedIndex]?.value;
    if (checked) {
      onChange([...new Set([...value, selected])]);
      return;
    }

    const index = value.indexOf(selected);
    if (index === -1) {
      return;
    }

    value.splice(index, 1);
    onChange(value);
  };

  return (
    <FormControl
      error={error}
      component="fieldset"
      className={classes.formControl}
    >
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {items.map((item, index) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value.includes(item.value)}
                onChange={handleChange}
                value={index}
              />
            }
            label={item.text}
          />
        ))}
      </FormGroup>
      {message && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  );
}

export default Checkboxs;
