import { ThemeProvider } from "@material-ui/core";
import LibButton from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import styled from "styled-components";

import Autocomplete from "../components/Autocomplete";
import ReceiptConfirmation from "./Receipt.Confirmation";
import Confirmation from "../components/Confirmation";
import Loader from "../components/Loader";
import TextField from "../components/TextField.Bet";
import OldTextField from "../components/TextField";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { uuid } from "../helpers/Common";
import { MIN_RULE, REQUIRED_RULE } from "../helpers/Form";
import useActiveMatchs from "../hooks/api/useActiveMatchs";
import useDownlines from "../hooks/api/useDownlines";
import useMatch from "../hooks/api/useMatch";
import useTxn from "../hooks/api/useTxn";
import useUser from "../hooks/api/useUser";
import useMount from "../hooks/useMount";
import { darkTheme } from "../theme";
import Receipt from "./Receipt";
import MatchCard from "../components/MatchCard";

const Title = styled(Typography).attrs({
  variant: "body",
})({
  display: "block",
  color: "rgb(249,177,78)",
  textAlign: "center",
});

const Button = styled(LibButton)({
  backgroundColor: "rgb(249,177,78)",
  borderRadius: 18,
  letterSpacing: "0.125em",
  marginTop: 10,
});

const BedContainer = styled(Grid).attrs({
  container: true,
  spacing: 4,
})({
  marginBottom: 16,
  paddingTop: 0,
  paddingBottom: 0,
});

const BedGrid = styled(Grid).attrs({
  item: true,
  xs: 4,
})({
  borderRight: "1px solid #fff",
});

const UsernameInput = styled(Autocomplete).attrs({
  label: "Username",
  margin: "dense",
  rules: REQUIRED_RULE,
})({});

const RemarkInput = styled(OldTextField).attrs({
  label: "Remark",
  margin: "dense",
  multiline: true,
  rows: 3,
  rowsMax: 3,
})({});

function Betting() {
  const { setPageTitle, setMessage } = useAppContext();
  const { user } = useAuthContext();
  const { id = "" } = useParams();

  const [showReceipt, setShowReceipt] = useState();
  const [bet, setBet] = useState();
  const [selected, setSelected] = useState();

  const {
    response: receipt,
    create: createTxn,
    loading: txnLoading,
  } = useTxn();

  const {
    refresh,
    loading: creditLoading,
    response: me,
  } = useUser({
    auto: true,
    id: "me",
  });

  const { role } = user ?? {};
  const isMasterRole = role === ROLE.master;

  const { masterId: mId, id: username } = me ?? {};
  const masterId = isMasterRole ? username : mId;

  const {
    response = [],
    loading: mLoading,
    refresh: refreshMatchs,
  } = useActiveMatchs({ id: masterId, activeOnly: false, liteOnly: true });

  const {
    response: match,
    loading: matchLoading,
    refreshById: refreshMatch,
  } = useMatch({
    auto: false,
  });

  const { response: downlines = [], loading: dLoading } = useDownlines();

  const loading = mLoading || dLoading;
  const anyLoading = txnLoading || creditLoading || matchLoading;

  const inputProps = {
    rules: {
      ...MIN_RULE(1),
    },
  };

  const form = useForm();
  const { handleSubmit, watch, reset } = form;

  const betId = useRef(uuid());

  useMount(() => {
    setPageTitle("Betting");
  });

  useEffect(() => {
    if (!masterId) {
      return;
    }

    refreshMatchs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterId]);

  useEffect(() => {
    if (!response || !id) {
      return;
    }

    const found = response.find((s) => s.id === id);
    setSelected(found);
  }, []);

  useEffect(() => {
    if (!response || !id) {
      return;
    }

    const found = response.find((s) => s.id === id);
    setSelected(found);
  }, [id, response]);

  useEffect(() => {
    refreshMatch(selected?.id);
  }, [selected?.id]);

  async function isValidBet(totalAmount) {
    if (totalAmount === 0) {
      setMessage({ message: "Cannot submit $0 bet" });
      return;
    }

    const { credit } = await refresh();
    if (totalAmount > credit) {
      setMessage({ message: "Credit not enough to proceed bet" });
      return;
    }

    return true;
  }

  async function onSubmit(data, item) {
    const { homeOdds, awayOdds, drawOdds } = item;
    const allOdds = [...homeOdds, ...awayOdds, ...drawOdds];

    if (anyLoading) {
      return;
    }

    const odds = allOdds.map((odd, index) => ({
      ...data.odds[index],
      ...odd,
    }));

    const formatted = {
      products: odds
        .filter((s) => !!s.betAmount)
        .map((s) => ({
          ...s,
          betAmount: +s.betAmount,
        })),
      remark: data.remark,
      paidBy: data.username?.value,
    };

    const totalAmount = formatted.products.reduce(
      (prev, odd) => prev + +odd.betAmount,
      0
    );

    const isValid = await isValidBet(totalAmount);
    if (!isValid) {
      return;
    }

    setBet(formatted);
  }

  function renderOdd({ score, amount }, index, limits, formName) {
    const name = `${formName}.${index}.betAmount`;
    return (
      <Grid key={name} xs={12}>
        <TextField
          {...inputProps}
          name={name}
          amount={amount}
          placeholder="$0"
          label={score}
        />
      </Grid>
    );
  }

  function renderForm() {
    if (matchLoading) {
      return (
        <Grid item xs={12}>
          <Loader />
        </Grid>
      );
    }

    const {
      id,
      home,
      away,
      homeOdds = [],
      awayOdds = [],
      drawOdds = [],

      homeOddsLimit = [],
      awayOddsLimit = [],
      drawOddsLimit = [],
    } = match || {}; // || item;
    const formValues = watch();

    const total = formValues[id]?.odds?.reduce(
      (prev, odd) => prev + +odd.betAmount,
      0
    );

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(() => onSubmit(formValues[id], match))}>
          <BedContainer>
            <Grid item xs={4}>
              <Title>{home}</Title>
            </Grid>
            <Grid item xs={4}>
              <Title>Draw</Title>
            </Grid>
            <Grid item xs={4}>
              <Title>{away}</Title>
            </Grid>

            <BedGrid>
              {homeOdds.map((item, index) =>
                renderOdd(item, index, homeOddsLimit, `${id}.odds`)
              )}
            </BedGrid>
            <BedGrid>
              {drawOdds.map((item, index) =>
                renderOdd(
                  item,
                  homeOdds.length + awayOdds.length + index,
                  drawOddsLimit,
                  `${id}.odds`
                )
              )}
            </BedGrid>
            <Grid item xs={4}>
              {awayOdds.map((item, index) =>
                renderOdd(
                  item,
                  homeOdds.length + index,
                  awayOddsLimit,
                  `${id}.odds`
                )
              )}
            </Grid>
          </BedContainer>
          <ThemeProvider theme={darkTheme}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <UsernameInput
                  name={`${id}.username`}
                  items={downlines.map(({ id }) => ({ text: id, value: id }))}
                />
                <RemarkInput name={`${id}.remark`} />
              </Grid>
            </Grid>
          </ThemeProvider>
          <Button type="submit" variant="contained">
            {creditLoading
              ? "Loading ..."
              : `Submit ${total ? `(Total: $${total})` : ""}`}
          </Button>
        </form>
      </FormProvider>
    );
  }

  return (
    <>
      <MatchCard {...match} id={null} />
      {renderForm()}
      <Receipt
        item={receipt}
        match={match}
        onClose={() => setShowReceipt()}
        open={showReceipt}
      />
      <ReceiptConfirmation
        open={!!bet}
        item={bet}
        match={match}
        loading={anyLoading}
        onClose={() => setBet()}
        onConfirm={async () => {
          if (anyLoading) {
            return;
          }

          try {
            const { id: matchId, playAt: matchAt, name: matchName } = selected;
            await createTxn({
              matchId,
              matchAt,
              matchName,
              ...bet,
              id: betId.current,
            });
            await refreshMatch(matchId);

            setBet();

            setShowReceipt(true);
            reset({ [matchId]: {} });

            // new uuid
            betId.current = uuid();
          } catch (ex) {
            setBet();
            setMessage(ex);
          }
        }}
      />
    </>
  );
}

export default Betting;
