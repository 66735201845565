import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useParams, useHistory } from "react-router";
import styled from "styled-components";
import logoImage from "../assets/logo.png";

import useMatch from "../hooks/api/useMatch";

import Loader from "../components/Loader";
import MatchCard from "../components/MatchCard";
import { useAppContext } from "../context/AppContext";

import { useScreenshot, createFileName } from "use-react-screenshot";
import { delay } from "../helpers/Common";
import { formatStandard } from "../helpers/DateTime";

import backgroundImage from "../assets/plate_bg.png";
import background2Image from "../assets/plate_bg2.png";
import background1Image from "../assets/plate_bg1.png";

const LogoContainer = styled.div({
  position: "absolute",
  bottom: 15,
  left: "50%",
  transform: "translateX(-50%)",
  opacity: 0.8,
});

const Logo = styled.img({
  width: 120,
});

const Url = styled.p({
  color: "rgba(255, 255, 255, .8)",
  fontSize: "0.8em",
  margin: 0,
  marginTop: -15,
});

const Container = styled.div({
  maxWidth: 500,
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  paddingLeft: 16,
  paddingRight: 16,
  marginLeft: -14,
  marginRight: -14,
  borderRadius: "0 0 25px 25px",
  paddingBottom: 8,
});

const Title = styled(Typography).attrs({
  variant: "body",
})({
  display: "block",
  textAlign: "center",
});

const Title2 = styled(Typography).attrs({
  variant: "body",
})({
  display: "block",
  color: "#fff",
  textAlign: "center",
});

const MainContainer = styled(Grid).attrs({
  container: true,
  spacing: 4,
})({
  marginBottom: 16,
  paddingTop: 0,
  paddingBottom: 0,
  position: "relative",
});

const MatchContainer = styled(Grid).attrs({
  container: true,
  // spacing: 4,
})({
  display: "flex",
  alignItems: "center",
  position: "relative",
});

const Content = styled(Grid).attrs({
  container: true,
  // spacing: 4,
})({
  display: "flex",
  alignItems: "center",
  position: "absolute",
});

const BackgroundImage = styled.img({
  width: "100%",
});

const BedGrid = styled(Grid).attrs({
  item: true,
  // spacing: 2,
  xs: 4,
})(({ last }) => ({
  borderRight: last ? "" : "1px solid #fff",
  padding: `0 !important`,
}));

const Liner = styled(Typography).attrs({
  variant: "p",
})({
  fontSize: "1.2em",
  textAlign: "center",
  color: "rgba(255,255,255, 1)",
  display: "block",
});

const Gap = styled.div({
  height: 16,
  width: "100%",
});

const OddContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 16,
});

const MatchScore = styled(Typography).attrs({
  component: "label",
})({
  color: "#fff",
  fontSize: "1.2rem",
  flex: 1,
  paddingTop: 4,
  paddingBottom: 4,
  paddingRight: 8,
  whiteSpace: "nowrap",
});

const MatchOdd = styled(Typography).attrs({
  component: "label",
})({
  color: "#000",
  backgroundColor: "#FFF4BC",
  borderRadius: "25px",
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 4,
  paddingBottom: 4,
  fontSize: "1.2rem",
  fontWeight: "bold",
  width: 50,
  display: "inline-block",
  textAlign: "center",
  marginRight: 8,
  whiteSpace: "nowrap",
});

const Footer = styled(Typography).attrs({
  variant: "p",
})({
  fontSize: "0.85em",
  textAlign: "center",
  color: "rgba(255,255,255, .5)",
  display: "block",
});

function OddReportDownload() {
  const { setPageTitle } = useAppContext();
  const { id } = useParams();
  const { response, loading } = useMatch({
    id,
    auto: true,
  });

  const containerRef = useRef();

  const [, takeScreenshot] = useScreenshot();
  const history = useHistory();

  const {
    name = "",
    home,
    away,
    homeOdds,
    awayOdds,
    drawOdds,
  } = response ?? {};

  useEffect(() => {
    if (!name) {
      return;
    }

    async function download() {
      await delay(1000);
      const screenshot = await takeScreenshot(containerRef.current, {
        useCORS: true,
        letterRendering: 1,
        allowTaint: false,
      });
      const a = document.createElement("a");
      a.href = screenshot;
      a.download = createFileName("jpg", name);
      a.click();

      history.replace(`/report/odd`);
    }

    download();
  }, [name]);

  useEffect(() => {
    setPageTitle(`Plate - ${name}`);
  }, [name, setPageTitle]);

  function renderOdd({ score, amount }) {
    return (
      <OddContainer>
        <MatchScore>{score}</MatchScore>
        <MatchOdd>{amount}</MatchOdd>
      </OddContainer>
    );
  }

  function renderDetail() {
    return (
      <Container ref={containerRef}>
        <MatchCard {...response} id={null} expand />
        <MainContainer>
          <MatchContainer>
            <BackgroundImage src={background2Image} />
            <Content>
              <Grid item xs={4}>
                <Title>{home}</Title>
              </Grid>
              <Grid item xs={4}>
                <Title>Draw</Title>
              </Grid>
              <Grid item xs={4}>
                <Title>{away}</Title>
              </Grid>
            </Content>
          </MatchContainer>

          <MatchContainer>
            <BackgroundImage src={background1Image} />
            <Content>
              <Grid item xs={2}>
                <Title2>Score</Title2>
              </Grid>
              <Grid item xs={2}>
                <Title2>Odd</Title2>
              </Grid>
              <Grid item xs={2}>
                <Title2>Score</Title2>
              </Grid>
              <Grid item xs={2}>
                <Title2>Odd</Title2>
              </Grid>
              <Grid item xs={2}>
                <Title2>Score</Title2>
              </Grid>
              <Grid item xs={2}>
                <Title2>Odd</Title2>
              </Grid>
            </Content>
          </MatchContainer>

          <Gap />
          <BedGrid>
            {homeOdds.map((item, index) =>
              renderOdd(item, index, "homeOdds", homeOdds)
            )}
          </BedGrid>
          <BedGrid>
            {drawOdds.map((item, index) =>
              renderOdd(item, index, "drawOdds", drawOdds)
            )}
          </BedGrid>
          <BedGrid last>
            {awayOdds.map((item, index) =>
              renderOdd(item, index, "awayOdds", awayOdds)
            )}
          </BedGrid>

          <LogoContainer>
            <Logo src={logoImage} />
            <Url>www.gsport.games</Url>
          </LogoContainer>
        </MainContainer>
        <Liner>** 90 MINUTES + INJURY TIME ONLY **</Liner>
      </Container>
    );
  }

  return (
    <Grid container spacing={2} align="center">
      <Grid item xs={12}>
        {loading ? <Loader paper /> : renderDetail()}
      </Grid>
    </Grid>
  );
}

export default OddReportDownload;
