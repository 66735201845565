import { InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import AppDivider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import DatePicker from "../components/Datepicker";
import Dropzone from "../components/Dropzone";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { REQUIRED_RULE } from "../helpers/Form";
import { numberWithCommas } from "../helpers/String";
import useBetReport from "../hooks/api/useBetReport";
import useFightReport from "../hooks/api/useFightReport";
import useForm from "../hooks/useForm";
import Switch from "../components/Switch";
import { delay } from "../helpers/Common";
import useFightReportBetAmount from "../hooks/api/useFightReportBetAmount";

const DividerContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const Divider = styled(AppDivider)(({ theme }) => ({
  flex: 1,
}));

const Flex = styled.div({ flex: 1 });

const IncludeDownlineSwitch = styled(Switch).attrs({
  name: "includeDownline",
  label: "Include Downline",
})({});

const DividerText = styled(Typography).attrs(({ theme }) => ({
  variant: "overline",
  display: "block",
  align: "center",
  color: "primary",
}))(({ theme }) => ({
  margin: theme.spacing(2),
}));

const ImageInput = styled(Dropzone).attrs({
  name: "image",
  label: "Image",
  url: "/match/image",
})({});

const HomeInput = styled(TextField).attrs({
  name: "home",
  label: "Home",
  rules: REQUIRED_RULE,
})({});

const AwayInput = styled(TextField).attrs({
  name: "away",
  label: "Away",
  rules: REQUIRED_RULE,
})({});

const MatchNoInput = styled(TextField).attrs({
  name: "matchNo",
  label: "Match No",
  rules: REQUIRED_RULE,
})({});

const ScoreInput = styled(TextField).attrs({
  name: "score",
  label: "Score",
  rules: REQUIRED_RULE,
})({});

const PlayAtInput = styled(DatePicker).attrs({
  name: "playAt",
  label: "Match Date",
  rules: REQUIRED_RULE,
  time: true,
})({});

const SeasonCupInput = styled(TextField).attrs({
  name: "season",
  label: "Season Cup",
  rules: REQUIRED_RULE,
})({});

const OddInput = styled(TextField).attrs({
  rules: REQUIRED_RULE,
  margin: "dense",
  type: "tel",
})({});

const HOME_ODS = [
  "1-0",
  "2-0",
  "2-1",
  "3-0",
  "3-1",
  "3-2",
  "4-0",
  "4-1",
  "4-2",
  "4-3",
];
const AWAY_ODS = [
  "0-1",
  "0-2",
  "1-2",
  "0-3",
  "1-3",
  "2-3",
  "0-4",
  "1-4",
  "2-4",
  "3-4",
];
const DRAW_ODS = ["0-0", "1-1", "2-2", "3-3", "4-4", "AOS"];

const Card = styled(LibCard)({
  //   marginBottom: 16,
});

function FightReport() {
  const { setPageTitle } = useAppContext();
  const { id } = useParams();
  const { response, loading, refresh } = useFightReport({
    id,
  });
  const {
    response: bResponse,
    loading: bLoading,
    refresh: bRefresh,
  } = useFightReportBetAmount({ id });

  const form = useForm();
  const inputProps = { disabled: true };

  const [includeDownline] = form.watch(["includeDownline"]);

  const {
    score,
    matchNo,
    image,
    name = "",
    home,
    away,
    homeOdds,
    awayOdds,
    drawOdds,
    playAt,
    season,
    totalFight = 0,
    withDownlines,
  } = response ?? {};

  const { totalBet } = bResponse ?? {};

  useEffect(() => {
    setPageTitle(`Fight  Report - ${name}`);
  }, [name, setPageTitle]);

  function renderOdd(score, index, formName, odds = {}) {
    const { fight, payout } = odds[index] ?? {};
    return (
      <Grid key={`${formName}.${index}.fight`} item lg={2} xs={6} md={4}>
        <OddInput
          {...inputProps}
          label={score}
          defaultValue={fight}
          name={`${formName}.${index}.fight`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">/ {payout}</InputAdornment>
            ),
          }}
        />
      </Grid>
    );
  }

  function renderWithDLOdd(score, index, formName, odds = {}) {
    const { fight, payout } = odds[index] ?? {};
    return (
      <Grid
        key={`withDownlines.${formName}.${index}.fight`}
        item
        lg={2}
        xs={6}
        md={4}
      >
        <OddInput
          {...inputProps}
          id={`withDownlines.${formName}.${index}.fight`}
          label={score}
          defaultValue={fight}
          name={`withDownlines.${formName}.${index}.fight`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">/ {payout}</InputAdornment>
            ),
          }}
        />
      </Grid>
    );
  }

  function renderDivider(title) {
    return (
      <DividerContent>
        <Divider />
        <DividerText>{title} Odd</DividerText>
        <Divider />
      </DividerContent>
    );
  }

  function renderDetail() {
    return (
      <FormProvider {...form}>
        <form>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ImageInput {...inputProps} defaultValue={image} />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <MatchNoInput {...inputProps} defaultValue={matchNo} />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <PlayAtInput {...inputProps} defaultValue={playAt} />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <SeasonCupInput {...inputProps} defaultValue={season} />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <HomeInput {...inputProps} defaultValue={home} />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <AwayInput {...inputProps} defaultValue={away} />
                </Grid>
                {score && (
                  <Grid item lg={3} md={6} xs={12}>
                    <ScoreInput {...inputProps} defaultValue={score} />
                  </Grid>
                )}
              </Grid>

              {includeDownline ? renderWithDLOdds() : renderOdds()}
            </CardContent>
            <CardActions>
              <Grid container>
                <Grid item xs={12} lg={3}>
                  <IncludeDownlineSwitch />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() => {
                      refresh();
                      bRefresh();
                    }}
                  >
                    Refresh Report
                  </Button>
                </Grid>

                <Flex />
                <Button component="span" color="textPrimary">
                  Total Fight: ${" "}
                  {numberWithCommas(
                    includeDownline ? withDownlines.totalFight : totalFight
                  )}
                </Button>
                <Button component="span" color="textPrimary">
                  Total Bet: $ {bLoading ? "Loading" : numberWithCommas(totalBet)}
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    );
  }

  function renderOdds() {
    return (
      <>
        {renderDivider(home || "Home")}
        <Grid container spacing={2}>
          {HOME_ODS.map((item, index) =>
            renderOdd(item, index, "homeOdds", homeOdds)
          )}
        </Grid>
        {renderDivider(away || "Away")}
        <Grid container spacing={2}>
          {AWAY_ODS.map((item, index) =>
            renderOdd(item, index, "awayOdds", awayOdds)
          )}
        </Grid>
        {renderDivider("Draw")}
        <Grid container spacing={2}>
          {DRAW_ODS.map((item, index) =>
            renderOdd(item, index, "drawOdds", drawOdds)
          )}
        </Grid>
      </>
    );
  }

  function renderWithDLOdds() {
    return (
      <>
        {renderDivider(home || "Home")}
        <Grid container spacing={2}>
          {HOME_ODS.map((item, index) =>
            renderWithDLOdd(item, index, "homeOdds", withDownlines.homeOdds)
          )}
        </Grid>
        {renderDivider(away || "Away")}
        <Grid container spacing={2}>
          {AWAY_ODS.map((item, index) =>
            renderWithDLOdd(item, index, "awayOdds", withDownlines.awayOdds)
          )}
        </Grid>
        {renderDivider("Draw")}
        <Grid container spacing={2}>
          {DRAW_ODS.map((item, index) =>
            renderWithDLOdd(item, index, "drawOdds", withDownlines.drawOdds)
          )}
        </Grid>
      </>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? <Loader paper /> : renderDetail()}
      </Grid>
    </Grid>
  );
}

export default FightReport;
