import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";
import Dropzone from "../components/Dropzone";
import useTeam from "../hooks/api/useTeam";
import { useParams } from "react-router-dom";

const ImageInput = styled(Dropzone).attrs({
  name: "image",
  label: "Image",
  url: "/team/image",
})({});

const NameInput = styled(TextField).attrs({
  name: "name",
  label: "Team Name",
  rules: REQUIRED_RULE,
})({});

function TeamDetail() {
  const { setPageTitle, setMessage } = useAppContext();
  const { id } = useParams();

  const { loading, update, response } = useTeam({ id, auto: true });

  const form = useForm();
  const { handleSubmit, reset } = form;

  useMount(() => {
    setPageTitle(`Team Add`);
  });

  useEffect(() => {
    reset(response);
  }, [response]);

  async function onSubmit(data) {
    try {
      await update(data);
      setMessage("Team added");
      reset();
    } catch (ex) {
      setMessage({ message: "Duplicated Team Updated" });
    }
  }

  function renderDetail() {
    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ImageInput />
                </Grid>
                <Grid item xs={12}>
                  <NameInput />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button type="submit" color="primary">
                Update Team
              </Button>
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    );
  }

  return (
    <Grid container spacing={2} justify="center">
      <Grid item lg={6} xs={12}>
        {loading ? <Loader /> : renderDetail()}
      </Grid>
    </Grid>
  );
}

export default TeamDetail;
