import { useEffect, useState } from "react";

import { API_URL } from "../../constants/EnvConfig";
import { useAuthContext } from "../../context/AuthContext";
import useAuthFetch from "../useAuthFetch";
import usePagedUsers from "./usePagedUsers";

function usePagedMatchWinTxns({ matchId } = {}) {
  const { loading, get } = useAuthFetch({});

  const { user: authUser } = useAuthContext();
  const { role: userRole } = authUser ?? {};

  const { response: users, loading: uLoading } = usePagedUsers();

  const [txns, setTxns] = useState([]);
  const [masterLoading, setMasterLoading] = useState(false);

  async function pageRefresh({ matchId, lastKey }) {
    const prefix = `${API_URL}/txn/page/win/${matchId}`;
    const url = lastKey ? `${prefix}?lastKey=${lastKey}` : prefix;
    return await get({ url });
  }

  async function refreshAll(params) {
    const { matchId, txns: all = [], lastKey: lk } = params || {};
    const paged = await pageRefresh({ matchId, lastKey: lk });
    const { items = [], lastKey } = paged || {};
    const updated = [...all, ...items];

    if (!lastKey) {
      return updated;
    }

    return refreshAll({ matchId, txns: updated, lastKey });
  }

  useEffect(() => {
    if (!matchId || userRole !== "master") {
      return;
    }

    async function init() {
      const txns = await refreshAll({ matchId });
      setTxns(txns);
    }

    init();
  }, [userRole, matchId]);

  useEffect(() => {
    if (!matchId || userRole === "master" || !users || users.length === 0) {
      return;
    }

    async function init() {
      setMasterLoading(true);
      const txns = await refreshAll({ matchId });
      const filtered = txns.filter((s) => users.includes(s.paidBy));
      setTxns(filtered);
      setMasterLoading(false);
    }

    init();
  }, [users, userRole, matchId]);

  return {
    response: txns,
    loading: masterLoading || uLoading || loading,
    refresh: refreshAll,
  };
}

export default usePagedMatchWinTxns;
