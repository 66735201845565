import { InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import AppDivider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import DatePicker from "../components/Datepicker";
import Dropzone from "../components/Dropzone";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { REQUIRED_RULE } from "../helpers/Form";
import { numberWithCommas } from "../helpers/String";
import useBetReport from "../hooks/api/useBetReport";
import useForm from "../hooks/useForm";
import BetReportLimit from "./BetReport.Limit";

const DividerContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const Divider = styled(AppDivider)(({ theme }) => ({
  flex: 1,
}));

const Flex = styled.div({ flex: 1 });

const DividerText = styled(Typography).attrs(({ theme }) => ({
  variant: "overline",
  display: "block",
  align: "center",
  color: "primary",
}))(({ theme }) => ({
  margin: theme.spacing(2),
}));

const ImageInput = styled(Dropzone).attrs({
  name: "image",
  label: "Image",
  url: "/match/image",
})({});

const HomeInput = styled(TextField).attrs({
  name: "home",
  label: "Home",
  rules: REQUIRED_RULE,
})({});

const AwayInput = styled(TextField).attrs({
  name: "away",
  label: "Away",
  rules: REQUIRED_RULE,
})({});

const MatchNoInput = styled(TextField).attrs({
  name: "matchNo",
  label: "Match No",
  rules: REQUIRED_RULE,
})({});

const ScoreInput = styled(TextField).attrs({
  name: "score",
  label: "Score",
  rules: REQUIRED_RULE,
})({});

const PlayAtInput = styled(DatePicker).attrs({
  name: "playAt",
  label: "Match Date",
  rules: REQUIRED_RULE,
  time: true,
})({});

const SeasonCupInput = styled(TextField).attrs({
  name: "season",
  label: "Season Cup",
  rules: REQUIRED_RULE,
})({});

const OddInput = styled(TextField).attrs({
  rules: REQUIRED_RULE,
  margin: "dense",
  type: "tel",
})({});

const HOME_ODS = [
  "1-0",
  "2-0",
  "2-1",
  "3-0",
  "3-1",
  "3-2",
  "4-0",
  "4-1",
  "4-2",
  "4-3",
];
const AWAY_ODS = [
  "0-1",
  "0-2",
  "1-2",
  "0-3",
  "1-3",
  "2-3",
  "0-4",
  "1-4",
  "2-4",
  "3-4",
];
const DRAW_ODS = ["0-0", "1-1", "2-2", "3-3", "4-4", "AOS"];

const Card = styled(LibCard)({
  //   marginBottom: 16,
});

function MatchDetail() {
  const { setPageTitle } = useAppContext();
  const { id } = useParams();
  const { response, loading, refresh } = useBetReport({
    id,
  });

  const {
    score,
    matchNo,
    image,
    name = "",
    home,
    away,
    homeOdds,
    awayOdds,
    drawOdds,
    playAt,
    season,
    totalBet = 0
  } = response ?? {};

  const form = useForm();
  const inputProps = { disabled: true };

  const [toggleLimit, setToggleLimit] = useState(false);

  useEffect(() => {
    setPageTitle(`Total Bet Report - ${name}`);
  }, [name, setPageTitle]);

  function renderOdd(score, index, formName, odds = {}) {
    const { bet = 0, odd = 0, total = 0 } = odds[index] ?? {};
    return (
      <Grid key={`${formName}.${index}.amount`} item lg={2} xs={6} md={4}>
        <OddInput
          {...inputProps}
          label={`${score} (x ${odd})`}
          defaultValue={`$ ${total}`}
          name={`${formName}.${index}.amount`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">x {bet} = </InputAdornment>
            ),
          }}
        />
      </Grid>
    );
  }

  function renderDivider(title) {
    return (
      <DividerContent>
        <Divider />
        <DividerText>{title} Odd</DividerText>
        <Divider />
      </DividerContent>
    );
  }

  function renderDetail() {
    return (
      <FormProvider {...form}>
        <form>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ImageInput {...inputProps} defaultValue={image} />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <MatchNoInput {...inputProps} defaultValue={matchNo} />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <PlayAtInput {...inputProps} defaultValue={playAt} />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <SeasonCupInput {...inputProps} defaultValue={season} />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <HomeInput {...inputProps} defaultValue={home} />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <AwayInput {...inputProps} defaultValue={away} />
                </Grid>
                {score && (
                  <Grid item lg={3} md={6} xs={12}>
                    <ScoreInput {...inputProps} defaultValue={score} />
                  </Grid>
                )}
              </Grid>

              {toggleLimit ? renderLimit() : renderOdds()}
            </CardContent>
            <CardActions>
              <Grid container>
                <Grid item xs={12} lg={3}>
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() => refresh()}
                  >
                    Refresh Report
                  </Button>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button
                    color="primary"
                    onClick={() => setToggleLimit((tg) => !tg)}
                  >
                    {toggleLimit ? "Show Odd" : "Show Limit"}
                  </Button>
                </Grid>
                <Flex />
                <Button component="span" color="textPrimary">
                  Total Bet: $ {numberWithCommas(totalBet)}
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    );
  }

  function renderOdds() {
    return (
      <>
        {renderDivider(home || "Home")}
        <Grid container spacing={2}>
          {HOME_ODS.map((item, index) =>
            renderOdd(item, index, "homeOdds", homeOdds)
          )}
        </Grid>
        {renderDivider(away || "Away")}
        <Grid container spacing={2}>
          {AWAY_ODS.map((item, index) =>
            renderOdd(item, index, "awayOdds", awayOdds)
          )}
        </Grid>
        {renderDivider("Draw")}
        <Grid container spacing={2}>
          {DRAW_ODS.map((item, index) =>
            renderOdd(item, index, "drawOdds", drawOdds)
          )}
        </Grid>
      </>
    );
  }

  function renderLimit() {
    return <BetReportLimit response={response} form={form} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? <Loader paper /> : renderDetail()}
      </Grid>
    </Grid>
  );
}

export default MatchDetail;
