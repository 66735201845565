import LibCard from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import LibCardContent from "@material-ui/core/CardContent";
import LibCardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { formatStandard } from "../helpers/DateTime";
import useTeam from "../hooks/api/useTeam";
import Loader from "./Loader";

const Card = styled(LibCard)(({ isExpired, expand }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  marginBottom: 20,
  filter: isExpired && "grayscale(100%)",
  marginLeft: expand ? -16 : 0,
  marginRight: expand ? -16 : 0,
}));

const CardMedia = styled.div({
  height: 80,
});

const AppCardMedia = styled(LibCardMedia)({
  height: 150,
});

const CardContent = styled(LibCardContent)({
  backgroundColor: "#fff",
});

const MatchNo = styled(Typography).attrs({
  variant: "h6",
  component: "h5",
})({
  flex: 1,
  textAlign: "center",
  display: "block",
  color: "rgb(209,33,91)",
  lineHeight: 1,
  alignSelf: "flex-end",
});

const VS = styled(MatchNo).attrs({
  variant: "h5",
  component: "h5",
})({
  paddingTop: 8,
  paddingLeft: 20,
  paddingRight: 20,
  fontWeight: "bold",
  alignSelf: "flex-start",
});

const TeamName = styled(Typography).attrs({
  variant: "body",
})({
  width: 90,
  textAlign: "center",
  display: "block",
  lineHeight: 1,
  marginTop: 8,
});

const PlayAt = styled(Typography).attrs({
  variant: "body2",
  color: "textSecondary",
  component: "p",
})({
  textAlign: "center",
  display: "block",
  lineHeight: 1,
  marginTop: 16,
});

const Team = styled.div(({ src }) => ({
  borderRadius: "100%",
  overflow: "hidden",
  width: 90,
  height: 90,
  border: "1px solid #fff",
  backgroundColor: "#fff",
  backgroundImage: `url(${src})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));

const TeamContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: -80,
});

const NameContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function MatchCard({
  id,
  image,
  homeId,
  awayId,
  matchNo,
  playAt,
  isExpired,
  expand,
}) {
  const areaProps = id ? { component: Link, to: `/betting/${id}` } : {};
  const { response: homeTeam, loading: homeLoading } = useTeam({
    id: homeId,
    auto: true,
  });
  const { response: awayTeam, loading: awayLoading } = useTeam({
    id: awayId,
    auto: true,
  });

  const loading = homeLoading || awayLoading;

  if (loading) {
    return <Loader paper />;
  }

  return (
    <Card isExpired={isExpired} expand={expand}>
      <CardActionArea {...areaProps}>
        {image ? <AppCardMedia image={image} /> : <CardMedia />}
        <CardContent>
          <TeamContainer>
            <Team src={homeTeam?.image} />
            <MatchNo>{matchNo}</MatchNo>
            <Team src={awayTeam?.image} />
          </TeamContainer>
          <NameContainer>
            <TeamName>{homeTeam?.name}</TeamName>
            <VS>VS</VS>
            <TeamName>{awayTeam?.name}</TeamName>
          </NameContainer>

          <PlayAt>{formatStandard(playAt)}</PlayAt>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default MatchCard;
