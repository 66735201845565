import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useFightReportBetAmount({ id }) {
  const url = `${API_URL}/fightTxn/r/${id}/betAmount`;
  const { response, loading, get } = useAuthFetch({ url });

  function refresh() {
    return get({ url });
  }

  return { response, loading, refresh };
}

export default useFightReportBetAmount;
