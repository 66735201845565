import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router";
import styled from "styled-components";

import Checkboxs from "../components/Checkboxs";
import Loader from "../components/Loader";
import PasswordPolicy from "../components/PasswordPolicy";
import Switch from "../components/Switch";
import TextField from "../components/TextField";
import { ROLE, ROLE_ACCESS } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { PASSWORD_POLICY, REQUIRED_RULE } from "../helpers/Form";
import useSubAccount from "../hooks/api/useSubAccount";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";
import Toggle from "../subAccount/Toggle";

const UsernameInput = styled(TextField).attrs({
  name: "id",
  label: "Username",
  rules: REQUIRED_RULE,
  disabled: true,
})({});

const FullnameInput = styled(TextField).attrs({
  name: "name",
  label: "Full Name",
  rules: REQUIRED_RULE,
})({});

const StatusInput = styled(TextField).attrs({
  name: "status",
  label: "Status",
  rules: REQUIRED_RULE,
  disabled: true,
})({});

const PasswordInput = styled(TextField).attrs({
  name: "password",
  label: "Password",
  type: "password",
  rules: { ...REQUIRED_RULE, pattern: PASSWORD_POLICY.pattern },
})({});

function UserDetail() {
  const { setPageTitle, setMessage } = useAppContext();
  const { id = "" } = useParams();

  const {
    response,
    loading: userLoading,
    refresh: userRefresh,
    update,
  } = useSubAccount({
    id,
    auto: true,
  });

  const loading = userLoading;
  const refresh = () => {
    userRefresh();
  };

  const { status } = response || {};

  const [toggleItem, setToggleItem] = useState();

  const form = useForm();
  const pwdForm = useForm();

  const { watch } = pwdForm;
  const { password } = watch();

  useMount(() => {
    setPageTitle(`Sub Account - ${id}`);
  });

  useEffect(() => {
    if (!response) {
      return;
    }

    const { access, ...rest } = response || {};

    pwdForm.reset(rest);

    const updated = ROLE_ACCESS.filter(({ value }) => value & +access).map(
      ({ value }) => value
    );

    form.reset(rest);
    form.setValue("access", updated);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  async function onSubmit({ password, access = [], ...data }) {
    try {
      await update({
        ...data,
        access: access.reduce((prev, acc) => prev + acc, 0),
      });
      setMessage("Sub Account updated");
    } catch (ex) {
      const { message = "Update sub account fail" } = ex;
      setMessage({ message });
      refresh();
    }
  }

  async function onPwdSubmit({ password }) {
    try {
      await update({ password });
      setMessage("Sub Account updated");
    } catch (ex) {
      setMessage({ message: "Update sub account fail" });
    }
  }

  function renderRoles() {
    return (
      <FormProvider {...form}>
        <Card component="form" onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FullnameInput />
              </Grid>
              <Grid item xs={12}>
                <Checkboxs
                  items={ROLE_ACCESS}
                  name="access"
                  label="Access List"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type="submit" color="primary">
              Update Sub Account
            </Button>
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  function renderPassword() {
    const policyProps = {
      value: password,
      isSubmitted: false,
    };

    return (
      <FormProvider {...pwdForm}>
        <Card component="form" onSubmit={pwdForm.handleSubmit(onPwdSubmit)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UsernameInput />
              </Grid>
              <Grid item xs={12}>
                <StatusInput />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput />
                <PasswordPolicy {...policyProps} />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type="submit" color="primary">
              Update Password
            </Button>

            <Button
              color="secondary"
              onClick={(e) => {
                setToggleItem(response);
              }}
            >
              {status === "active" ? "Disable " : "Enable "} Sub Account
            </Button>
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          {loading ? <Loader paper /> : renderPassword()}
        </Grid>
        <Grid item xs={12} lg={6}>
          {loading ? <Loader paper /> : renderRoles()}
        </Grid>
      </Grid>
      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => refresh()}
      />
    </>
  );
}

export default UserDetail;
