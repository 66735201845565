import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";

import { useAppContext } from "../context/AppContext";
import useUser from "../hooks/api/useUser";
import useMount from "../hooks/useMount";
import Toggle from "./Toggle";
import Users from "./Users";

function SubAccount() {
  const { setPageTitle } = useAppContext();

  const { refresh: userRefresh } = useUser({
    id: "me",
    auto: true,
  });
  const refresh = () => {
    userRefresh();
  };

  const [toggleItem, setToggleItem] = useState();

  useMount(() => {
    setPageTitle(`Sub Account`);
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Users />
        </Grid>
      </Grid>
      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => refresh()}
      />
    </>
  );
}

export default SubAccount;
