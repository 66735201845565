import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import bgImage from "../assets/bg.jpg";
import logoImage from "../assets/logo.png";
import Copyright from "../components/Copyright";
import Loader from "../components/Loader";
import { Root, Container as LibContainer } from "../components/SharedLayout";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useForm from "../hooks/useForm";
import { darkTheme } from "../theme";

const UsernameInput = styled(TextField).attrs({
  label: "Username",
  name: "username",
  rules: REQUIRED_RULE,
  autoFocus: true,
})({});

const PasswordInput = styled(TextField).attrs({
  type: "password",
  label: "Password",
  name: "password",
  rules: REQUIRED_RULE,
})({});

const TwoFaInput = styled(TextField).attrs({
  label: "Google Auth",
  name: "gAuth",
  // rules: REQUIRED_RULE,,
})({});

const Container = styled(LibContainer)({
  height: "100vh",
  backgroundImage: `url(${bgImage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",
  padding: 16,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Logo = styled.img({
  width: 200,
  marginTop: 80,
  marginBottom: 50,
});

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  paper: {},
  logo: {
    width: 200,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LogIn() {
  const classes = useStyles();
  const form = useForm();
  const { handleSubmit } = form;

  const { signIn } = useAuthContext();
  const [loading, setLoading] = useState();
  const { setMessage } = useAppContext();

  const history = useHistory();

  const { state } = useLocation();
  const { from: fromLocation } = state || {};

  async function onSubmit(data) {
    setLoading(true);

    const { username, password } = data;
    const email = `${username}`.toLowerCase();
    try {
      const creds = { email, password };
      await signIn(creds);
      if (fromLocation) {
        history.replace(fromLocation);
      } else {
        history.replace("/");
      }
    } catch (ex) {
      setMessage(ex);
    }

    setLoading(false);
  }

  function renderForm() {
    return (
      <MuiThemeProvider theme={darkTheme}>
        <FormProvider {...form}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UsernameInput />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput />
              </Grid>
              {/* <Grid item xs={12}>
                <TwoFaInput />
              </Grid> */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </FormProvider>
      </MuiThemeProvider>
    );
  }

  return (
    <Root>
      <Container>
        <Logo src={logoImage} alt="logo" />
        {loading ? <Loader /> : renderForm()}
      </Container>
    </Root>
  );
}

export default LogIn;
