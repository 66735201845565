import { useEffect, useState } from "react";

import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

// import useFetch from "../useFetch";

function usePagedReceiptDateTxns({ receiptDate } = {}) {
  const { loading, get } = useAuthFetch({});

  const [txns, setTxns] = useState([]);

  async function pageRefresh({ receiptDate, lastKey }) {
    const prefix = `${API_URL}/txn/page/receipt/${receiptDate}`;
    const url = lastKey ? `${prefix}?lastKey=${lastKey}` : prefix;
    return await get({ url });
  }

  async function refreshAll(params) {
    const { receiptDate, txns: all = [], lastKey: lk } = params || {};
    const paged = await pageRefresh({ receiptDate, lastKey: lk });
    const { items = [], lastKey } = paged || {};
    const updated = [...all, ...items];

    if (!lastKey) {
      setTxns(updated);
      return updated;
    }

    return refreshAll({ receiptDate, txns: updated, lastKey });
  }

  useEffect(() => {
    if (!receiptDate) {
      return;
    }

    refreshAll({ receiptDate });
  }, [receiptDate]);

  return { response: txns, loading, refresh: refreshAll };
}

export default usePagedReceiptDateTxns;
