import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import Loader from "../components/Loader";
import Table from "../components/Table";
import { formatStandard } from "../helpers/DateTime";
import useAgentDownlines from "../hooks/api/useAgentDownlines";
import Filter from "./Filter.All";

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: status === "inactive" ? red[900] : green[500],
  marginRight: theme.spacing(1),
}));

const Name = styled.span(({ head, theme }) => ({
  flex: 1,
  paddingLeft: head && 10 + theme.spacing(1),
  paddingRight: theme.spacing(5),
}));

function Users({ id }) {
  const history = useHistory();
  const [showFilter, setShowFilter] = useState();
  const [filterText, setFilterText] = useState();

  const { response, loading } = useAgentDownlines({
    auto: true,
    id,
  });

  const filtered = useMemo(() => {
    if (!filterText) {
      return response || [];
    }

    const { id: fId } = filterText;

    return response?.filter(({ id }) =>
      `${id}`.toLowerCase().includes(`${fId}`.toLowerCase())
    );
  }, [filterText, response]);

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Username",
      custom: renderNameAndAction,
      customHeader: renderNameAndActionHeader,
    },
    {
      id: "agentLevel",
      numeric: false,
      label: "Level",
      format: ({ agentLevel }) => +agentLevel - 1,
    },
    { id: "agentFrom", numeric: false, label: "Upline" },
    {
      id: "updatedAt",
      numeric: false,
      label: "Update At",
      format: (item) => formatStandard(item.updatedAt),
    },
  ];

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async (data) => {
      setShowFilter();
      setFilterText(data);
    },
  };

  const tableProps = {
    headCells,
    items: filtered,
    rowsPerPage: 10,
    title: "Other level agents",
    uniqueId: "username",
    defaultSort: "agentLevel",
    onFilter: () => {
      setShowFilter(true);
    },
    onSelect: ({ id }) => {
      history.push(`/user/management/${id}/agent`);
    },
  };

  function renderNameAndActionHeader(label) {
    return (
      <StatusContent>
        <Name head>{label}</Name>
      </StatusContent>
    );
  }

  function renderNameAndAction(item) {
    const { id, status } = item;
    return (
      <StatusContent>
        <Dot status={status} />
        <Name>{id}</Name>
      </StatusContent>
    );
  }

  return (
    <>
      {loading ? <Loader paper /> : <Table {...tableProps} />}
      <Filter {...filterProps} />
    </>
  );
}

export default Users;
