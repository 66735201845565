import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useUplineWinLoseReport({ id }) {
  const url = `${API_URL}/report/winlose/${id}/upline`;
  const { response, loading } = useAuthFetch({ url});

  return { response, loading };
}

export default useUplineWinLoseReport;
