import moment from "moment";

export const getTimezoneByCountry = (timezoneCountry) => {
  let timezone = "+0800";
  if (
    timezoneCountry.toLowerCase() === "id" ||
    timezoneCountry.toLowerCase() === "vn"
  ) {
    timezone = "+0700";
  }
  return timezone;
};

export const getServerDate = (country, date) => {
  const timezone = getTimezoneByCountry(country);
  const dateStr = moment(date || new Date())
    .utcOffset(timezone)
    .format("DD MMM yyyy");
  return dateStr;
};

export const dateTimezoneFormat = (date, timezone) => {
  return moment(date || new Date()).format(`yyyy-MM-DD\\THH:mm:ss${timezone}`);
};

export const formatCountdown = (timer) => {
  const minute = `${Math.floor(timer / 60)}`.padStart(2, "0");
  const second = `${timer % 60}`.padStart(2, "0");
  return `${minute} minute ${second} seconds`;
};

export const formatDuration = (duration) => {
  return `${duration} mins`;
};

export const formatStandard = (date) => {
  return moment(date || new Date()).format(`DD MMM yyyy hh:mm a`);
};

export const getDaysBetweenDates = (startDate, endDate) => {
  const now = startDate.clone();
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.startOf("day").toJSON());
    now.add(1, "days");
  }
  return dates;
};
