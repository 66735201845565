import React from "react";
import Confirmation from "../components/Confirmation";
import useSubAccount from "../hooks/api/useSubAccount";

function Toggle({ item, open, onClose, onConfirm }) {
  const { id, status } = item ?? {};
  const { loading, toggle } = useSubAccount({ id });

  const active = status === "active";

  const conProps = {
    title: active ? "Disable Sub Account" : "Enable Sub Account",
    desc: `Are you sure want to ${active ? "disable" : "enable"} ${id}?`,
    open,
    onClose,
    loading,
    onConfirm: async () => {
      await toggle();
      onConfirm();
      onClose();
    },
  };
  return <Confirmation {...conProps} />;
}

export default Toggle;
