import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import DatePicker from "../components/Datepicker";
import TextField from "../components/TextField";
import useForm from "../hooks/useForm";

const NameInput = styled(TextField).attrs({
  name: "name",
  label: "Name",
})({});

function Filter({ open, onSearch, onClose }) {
  const form = useForm();
  const { handleSubmit, reset } = form;

  function renderContent() {
    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSearch)}>
          <DialogTitle>Filter</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <NameInput />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                reset();
                onSearch();
              }}
              color="primary"
            >
              Reset
            </Button>
            <Button type="submit" color="primary">
              Search
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    );
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      {renderContent()}
    </Dialog>
  );
}

export default Filter;
