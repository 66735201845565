import Button from "@material-ui/core/Button";
import LibDialog from "@material-ui/core/Dialog";
import LibDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import LibDivider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import MatchCard from "../components/MatchCard";
import { formatStandard } from "../helpers/DateTime";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";

import { useScreenshot, createFileName } from "use-react-screenshot";
import { delay } from "../helpers/Common";
import { compareMatchScore } from "../helpers/Match";

const Row = styled.div(({ space, theme, win }) => ({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  marginBottom: theme.spacing(space),
  paddingLeft: 16,
  paddingRight: 16,
  marginLeft: -16,
  marginRight: -16,
  backgroundColor: win ? "rgba(26,146,77, .2)" : "",

  "*": {
    fontSize: win && "1.15em",
  },
}));

const TotalRow = styled(Row)(({ voidTicket, theme }) => ({
  backgroundColor: voidTicket ? "rgba(255,0,0, .2)" : "rgba(26,146,77, .2)",
  margin: 0,
  padding: 16,
  marginLeft: -16,
  marginRight: -16,
}));

const Col = styled.div(({ align }) => ({
  display: "flex",
  flex: 1,
  justifyContent: align === "right" ? "flex-end" : "",
}));

const ReceiptNo = styled(Typography).attrs({
  variant: "subtitle2",
})(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const Head = styled(Typography).attrs({
  variant: "overline",
  color: "textSecondary",
})({});

const Name = styled(Typography).attrs(({ main }) => ({
  variant: main ? "body1" : "subtitle2",
  color: "textSecondary",
}))(({ error, main, theme }) => ({
  color: error ? "#FF0000" : "",
  fontWeight: error ? "bold" : "",
  //   paddingLeft: main ? 0 : theme.spacing(1),
}));

const Price = styled(Typography).attrs({
  variant: "subtitle2",
  color: "textSecondary",
})(({ error, main, theme }) => ({
  color: error ? "#FF0000" : "",
  fontWeight: "bold",
  //   paddingLeft: main ? 0 : theme.spacing(1),
}));

const Total = styled(Typography).attrs(({ main }) => ({
  variant: "h6",
}))(({ htmlColor }) => ({
  color: htmlColor ?? "",
  //   paddingLeft: main ? 0 : theme.spacing(1),
}));

const Footer = styled(Typography).attrs(({ main }) => ({
  variant: "h6",
}))({
  fontSize: "0.95em",
  textAlign: "center",
  marginBottom: 10,
});

const Divider = styled(LibDivider)(({ theme, noGap }) => ({
  marginBottom: noGap ? 0 : theme.spacing(2),
  marginLeft: noGap && -16,
  marginRight: noGap && -16,
}));

const BottomText = styled(Typography).attrs(({ main }) => ({
  variant: "body2",
  color: "textSecondary",
}))(({ main }) => ({
  fontWeight: main && "bold",
}));

const CloseButton = styled(Button)({
  backgroundColor: "rgb(26,146,77)",
  borderRadius: 18,
  letterSpacing: "0.125em",
  color: "#fff",
  paddingLeft: 16,
  paddingRight: 16,

  "&:hover": {
    backgroundColor: "#357a38",
  },
});

const DownloadButton = styled(Button)({
  letterSpacing: "0.125em",
});

const DialogActions = styled(LibDialogActions)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  marginBottom: 16,
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const Dialog = withStyles((theme) => ({
  paper: {
    borderRadius: 0,
    width: `calc(100% - 32px)`,
    margin: 16,
  },
}))(LibDialog);

function Receipt({ item, open, match, onClose, winTxn = false }) {
  const [, takeScreenshot] = useScreenshot();
  const containerRef = useRef();
  const [loading, setLoading] = useState();

  if (!item) {
    return null;
  }

  const {
    status,
    receiptNo,
    products = [],
    totalAmount,
    remark,
    paidBy,
    createdAt,
  } = item;

  async function onDownload() {
    setLoading(true);
    await delay(500);
    const screenshot = await takeScreenshot(containerRef.current, {
      useCORS: true,
      letterRendering: 1,
      allowTaint: false,
    });
    const a = document.createElement("a");
    a.href = screenshot;
    a.download = createFileName("jpg", receiptNo);
    a.click();
    setLoading(false);
  }

  const voidTicket = status === "void";

  const footer = voidTicket
    ? "Bet cancelled."
    : "Bet confirmed. Thank you for choosing us.";

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
      ref={containerRef}
    >
      <DialogContent>
        <ReceiptNo>
          Receipt No: {receiptNo} {voidTicket && "(VOID)"}
        </ReceiptNo>
        <Row>
          <Col>
            <Head>Products</Head>
          </Col>
          <Col align="right">
            <Head>Odds</Head>
          </Col>
          <Col align="right">
            <Head>bets</Head>
          </Col>
        </Row>
        <Divider />
        {products.map((prd) => (
          <Row
            space={2}
            win={winTxn && compareMatchScore(prd.score, match.score)}
          >
            <Col>
              <Name error={!!prd.remark}>{prd.score}</Name>
            </Col>
            <Col align="right">
              <Name error={!!prd.remark}>{prd.remark ?? prd.amount}</Name>
            </Col>
            <Col align="right">
              <Price error={!!prd.remark}>${prd.betAmount}</Price>
            </Col>
          </Row>
        ))}
        <Divider noGap />
        <TotalRow space={2} voidTicket={voidTicket}>
          <Col>
            {voidTicket ? (
              <ErrorIcon htmlColor="rgb(255,0,0)" fontSize="large" />
            ) : (
              <DoneIcon htmlColor="rgb(26,146,77)" fontSize="large" />
            )}
          </Col>
          <Col>
            <Total>Total</Total>
          </Col>
          <Col align="right">
            <Total
              main
              htmlColor={voidTicket ? "rgb(255,0,0)" : "rgb(26,146,77)"}
            >
              $ {totalAmount}
            </Total>
          </Col>
        </TotalRow>
        <Divider />

        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Remark</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{remark || "-"}</BottomText>
          </Col>
        </Row>

        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Paid by</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{paidBy}</BottomText>
          </Col>
        </Row>
        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Paid at</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{formatStandard(createdAt)}</BottomText>
          </Col>
        </Row>
      </DialogContent>

      {!loading && (
        <DialogActions>
          <DownloadButton onClick={onDownload} color="primary">
            Download
          </DownloadButton>
          <CloseButton onClick={onClose}>Close</CloseButton>
        </DialogActions>
      )}
      <Footer>{footer}</Footer>
      <MatchCard {...match} id={null} />
    </Dialog>
  );
}

export default Receipt;
