import Grid from "@material-ui/core/Grid";
import moment from "moment";
import React, { useEffect, useState } from "react";

import mock1Image from "../assets/mock1.jpg";
import FilterChips from "../components/FilterChips";
import Loader from "../components/Loader";
import MatchCard from "../components/MatchCard";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import useMatch from "../hooks/api/useMatch";
import useUser from "../hooks/api/useUser";
import useMount from "../hooks/useMount";

const CUTOFF_SEC = 60;

// import mock4Image from "../assets/mock4.jpg";
function Home() {
  const { setPageTitle } = useAppContext();
  const { user } = useAuthContext();
  const { role: userRole } = user ?? {};

  const [selectedFilter, setSelectedFilter] = useState([]);

  const { response: me } = useUser({
    auto: true,
    id: "me",
  });
  const { masterId, id: username } = me ?? {};
  const isMaster = userRole === ROLE.master;
  const id = isMaster ? username : masterId;

  const {
    response = [],
    loading,
    refresh,
  } = useMatch({
    id: `all/${id}`,
    activeOnly: !isMaster,
  });

  useMount(() => {
    setPageTitle("Home");
  });

  useEffect(() => {
    if (!id) {
      return;
    }

    refresh();
  }, [id]);

  const onFilterItems = (filter) => {
    setSelectedFilter(filter);
  };

  const getFiltered = () => {
    if (selectedFilter.length === 0) {
      return response;
    }

    return response.filter(({ playAt }) => {
      const validToday = moment(playAt).isBetween(
        moment().startOf("day").add(12),
        moment().endOf("day").add(12)
      );
      const validTomorrow = moment(playAt).isSameOrAfter(
        moment().add(1, "day").startOf("day").add(12)
      );

      if (selectedFilter.includes("Today Match") && validToday) {
        return true;
      }

      if (selectedFilter.includes("Early Match") && validTomorrow) {
        return true;
      }

      return false;
    });
  };

  function isExpired(item) {
    const { score, playAt } = item;
    if (score) {
      return true;
    }

    const cutOff = moment(playAt).subtract(CUTOFF_SEC, "seconds");
    const expired = moment(cutOff).isSameOrBefore(moment());
    return expired;
  }

  function renderItem(item) {
    return (
      <Grid item md={6} xs={12}>
        <MatchCard {...item} isExpired={isExpired(item)} />
      </Grid>
    );
  }

  const filtered = getFiltered();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FilterChips onChange={onFilterItems} />
      </Grid>
      {loading ? <Loader paer /> : filtered.map(renderItem)}
    </Grid>
  );
}

export default Home;
