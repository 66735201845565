import moment from "moment";
import { useEffect, useState } from "react";

import { useAuthContext } from "../../context/AuthContext";
import usePagedReceiptDateTxns from "./usePagedReceiptDateTxns";
import usePagedUsers from "./usePagedUsers";

// import useFetch from "../useFetch";

const DAYS = 30;

function useDaysReceiptDateTxns() {
  const { user: authUser } = useAuthContext();
  const { role: userRole } = authUser ?? {};

  const { response: users, loading: uLoading } = usePagedUsers();
  const { refresh: tRefresh } = usePagedReceiptDateTxns();

  const [allTxns, setAllTxns] = useState([]);
  const [masterLoading, setMasterLoading] = useState();

  useEffect(() => {
    if (userRole !== "master") {
      return;
    }

    const includedDays = Array(DAYS)
      .fill(0)
      .map((_, index) => moment().add(-index, "d").format("YYYYMMDD"));

    init();

    async function init() {
      setMasterLoading(true);
      const all = await Promise.all(
        includedDays.map((receiptDate) => tRefresh({ receiptDate }))
      );
      setAllTxns(all.flat());
      setMasterLoading(false);
    }
  }, [userRole]);

  useEffect(() => {
    if (userRole === "master" || !users || users.length === 0) {
      return;
    }

    const includedDays = Array(DAYS)
      .fill(0)
      .map((_, index) => moment().add(-index, "d").format("YYYYMMDD"));

    init();

    async function init() {
      setMasterLoading(true);
      const all = await Promise.all(
        includedDays.map((receiptDate) => tRefresh({ receiptDate }))
      );

      const filtered = all.flat().filter((s) => users.includes(s.paidBy));
      setAllTxns(filtered);
      setMasterLoading(false);
    }
  }, [users, userRole]);

  return { response: allTxns, loading: uLoading || masterLoading };
}

export default useDaysReceiptDateTxns;
