import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import Loader from "../components/Loader";
import Table from "../components/Table";
import { useAppContext } from "../context/AppContext";
import { formatStandard } from "../helpers/DateTime";
import useSeason from "../hooks/api/useSeason";
import useMount from "../hooks/useMount";
import Filter from "./Filter";
import { EMPTY_IMAGE } from "../constants/Image";

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Name = styled.span(({ head, theme }) => ({
  flex: 1,
  paddingLeft: head ? theme.spacing(5) : 0, // theme.spacing(1),
  paddingRight: head ? 0 : theme.spacing(5),
}));

const Logo = styled.img({
  width: 25,
  height: 25,
  borderRadius: "100%",
  objectFit: "cover",
  marginRight: 10,
});

function Seasons() {
  const { setPageTitle } = useAppContext();

  const history = useHistory();
  const [showFilter, setShowFilter] = useState();
  const [filterText, setFilterText] = useState();

  const { response, loading } = useSeason({ auto: true });

  const filtered = useMemo(() => {
    if (!filterText) {
      return response || [];
    }

    return response?.filter(({ name }) =>
      `${name}`.toLowerCase().includes(`${filterText}`.toLowerCase())
    );
  }, [filterText, response]);

  useMount(() => {
    setPageTitle("Season");
  });

  function renderNameAndAction(item) {
    const { name, image } = item;
    return (
      <StatusContent>
        <Logo src={image || EMPTY_IMAGE} />
        <Name>{name}</Name>
      </StatusContent>
    );
  }

  const headCells = [
    {
      id: "name",
      numeric: false,
      label: "Name",
      custom: renderNameAndAction,
    },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: (item) => formatStandard(item.createdAt),
    },
  ];

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async ({ name } = {}) => {
      setShowFilter();
      setFilterText(name);
    },
  };

  const tableProps = {
    headCells,
    items: filtered,
    rowsPerPage: 15,
    title: "Seasons",
    uniqueId: "name",
    defaultSort: "createdAt",
    defaultOrder: "desc",
    onAdd: () => {
      history.push("/season/add");
    },
    onFilter: () => {
      setShowFilter(true);
    },
    onSelect: ({ id }) => {
      history.push(`/season/${id}`);
    },
  };

  return (
    <>
      {loading ? <Loader paper /> : <Table {...tableProps} />}
      <Filter {...filterProps} />
    </>
  );
}

export default Seasons;
