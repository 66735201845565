import React from "react";
import styled from "styled-components";

import LibTickIcon from "@material-ui/icons/Check";
import LibCloseIcon from "@material-ui/icons/Close";
import LibDashIcon from "@material-ui/icons/Remove";

import { PASSWORD_POLICY } from "../helpers/Form";
import { Typography } from "@material-ui/core";

const MODE = {
  title: "#000",
  error: "#f44336",
  match: "#4caf50",
};

const POLICY = [
  {
    match: PASSWORD_POLICY.moreThan8Characters,
    text: "Be a minimum of 8 characters",
  },
  {
    match: PASSWORD_POLICY.uppercase,
    text: "Include at least one uppercase letter (A-Z)",
  },
  {
    match: PASSWORD_POLICY.lowercase,
    text: "Include at least one lowercase letter (a-z)",
  },
  {
    match: PASSWORD_POLICY.number,
    text: "Include at least one number (0-9)",
  },
  {
    match: PASSWORD_POLICY.symbol,
    text: "Include at least one special characters",
  },
];

const TickIcon = styled(LibTickIcon)({
  color: MODE.match,
});

const CloseIcon = styled(LibCloseIcon)({
  color: MODE.error,
});

const DashIcon = styled(LibDashIcon)({
  color: MODE.title,
});

const Container = styled.div(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const Text = styled(Typography)(({ mode = "title", icon, theme }) => ({
  color: MODE[mode],
  paddingLeft: icon && theme.spacing(1),
}));

const Content = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

function PasswordPolicy({ value = "", isSubmitted, style }) {
  const contProps = {
    style,
  };

  function renderContent({ text, match }) {
    const regex = new RegExp(match);
    const valid = value && regex.test(value);
    const mode = getMode(valid);
    const textProps = { mode, icon: true, children: text, variant: "body2" };
    return (
      <Content key={text}>
        {renderIcon(valid)}
        <Text {...textProps} />
      </Content>
    );
  }

  function renderIcon(valid) {
    if (valid) {
      return <TickIcon fontSize="inherit" />;
    }

    return isSubmitted ? (
      <CloseIcon fontSize="inherit" />
    ) : (
      <DashIcon fontSize="inherit" />
    );
  }

  function getMode(valid) {
    if (valid) {
      return "match";
    }

    return isSubmitted ? "error" : "title";
  }
  return (
    <Container {...contProps}>
      <Text variant="overline">Password must</Text>
      {POLICY.map(renderContent)}
    </Container>
  );
}

export default PasswordPolicy;
