import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import FormHelperText from "@material-ui/core/FormHelperText";
import ErrorIcon from "@material-ui/icons/Error";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const MatchScore = styled(Typography).attrs({
  component: "label",
})({
  color: "#fff",
  fontSize: "1.2rem",
});

const Input = styled.input({
  border: "none",
  flex: 1,
  height: 30,
  width: "100%",
  fontSize: 16,
  backgroundColor: "transparent",
  position: "relative",
  outline: "none",
  textAlign: "right",
  paddingRight: 10,

  "::placeholder": {
    color: "rgb(211,211,211)",
  },
});

const InputContainer = styled.div(({ hasValue, disabled }) => ({
  borderRadius: 15,
  width: "100%",
  overflow: "hidden",
  display: "flex",
  borderWidth: 2,
  borderStyle: "solid",
  backgroundColor: disabled ? "rgba(211,211,211, 1)" : "#fff",
  position: "relative",
  borderColor: disabled
    ? "rgb(211,211,211)"
    : hasValue
    ? "rgb(235,54,132)"
    : "#fff",

  ":before": {
    content: hasValue ? `""` : "",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(235,54,132, .2)",

  },
}));

const OddLabel = styled.label(({ readOnly }) => ({
  color: "rgb(235,54,132)",
  width: readOnly ? "100%" : 42,
  textAlign: "center",
  lineHeight: "30px",
}));

const LimitLabel = styled.label({
  color: "rgb(235,54,132)",
  textAlign: "center",
  lineHeight: "30px",
  paddingRight: 10,
});

const HelperText = withStyles((theme) => ({
  root: {
    display: "flex",
    color: "#ffeb3b",
  },
}))(FormHelperText);

function TextField({
  label,
  name,
  rules,
  defaultValue = "",
  disabled,
  amount,
  placeholder,
  limit,
  showLimit,
}) {
  const { control } = useFormContext();
  const [focus, setFocus] = useState();

  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const props = {
    id: label,
    placeholder,
    disabled,
    value,
    ref: inputRef,
    onChange: (e) => onChange(e.target.value),
    onFocus: () => setFocus(true),
    onBlur: () => setFocus(false),
  };

  // const showLimit = true; //false; //focus ? false : !value && limit !== undefined;

  return (
    <Container>
      <MatchScore htmlFor={label}>{label}</MatchScore>
      <InputContainer hasValue={!!value} disabled={disabled}>
        <OddLabel htmlFor={label}>{amount}</OddLabel>
        <Input {...props} />
        {showLimit && <LimitLabel htmlFor={label}>/{limit}</LimitLabel>}
      </InputContainer>
      {message && (
        <HelperText>
          <ErrorIcon fontSize="small" alignmentBaseline="baseline" />
          {message}
        </HelperText>
      )}
    </Container>
  );
}

export function TextFieldReadOnly({
  label,
  name,
  rules,
  defaultValue = "",
  disabled,
  amount,
  placeholder,
}) {
  return (
    <Container>
      <MatchScore htmlFor={label}>{label}</MatchScore>
      <InputContainer>
        <OddLabel readOnly htmlFor={label}>
          {amount}
        </OddLabel>
      </InputContainer>
    </Container>
  );
}

export default TextField;
