import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import Loader from "../components/Loader";
import Select from "../components/Select";
import PasswordPolicy from "../components/PasswordPolicy";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import {
  ALPHA_NUMERIC_RULE,
  MAX_RULE,
  MIN_RULE,
  PASSWORD_POLICY,
  REQUIRED_RULE,
} from "../helpers/Form";
import useUser from "../hooks/api/useUser";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";
import { ROLE, ALLOW_ROLES, ROLE_ACCESS } from "../constants/Role";
import { useAuthContext } from "../context/AuthContext";
import { useParams } from "react-router";
import { InputAdornment } from "@material-ui/core";
import useAgent from "../hooks/api/useAgent";
import Switch from "../components/Switch";
import Checkboxs from "../components/Checkboxs";
import useSubAccount from "../hooks/api/useSubAccount";

const UsernameInput = styled(TextField).attrs({
  name: "username",
  label: "Username",
  rules: { ...REQUIRED_RULE, pattern: ALPHA_NUMERIC_RULE.pattern },
})({});

const FullnameInput = styled(TextField).attrs({
  name: "name",
  label: "Full Name",
  rules: REQUIRED_RULE,
})({});

const PasswordInput = styled(TextField).attrs({
  name: "password",
  label: "Password",
  type: "password",
  rules: { ...REQUIRED_RULE, pattern: PASSWORD_POLICY.pattern },
})({});

function AddUser() {
  const { setPageTitle, setMessage } = useAppContext();
  const { loading, create } = useSubAccount();

  const form = useForm({
    defaultValues: {},
  });
  const { handleSubmit, watch, reset } = form;
  const { password } = watch();

  useMount(() => {
    setPageTitle(`Sub Account Add`);
  });

  async function onSubmit({ access = [], ...data }) {
    try {
      await create({
        ...data,
        access: access.reduce((prev, acc) => prev + acc, 0),
      });
      setMessage("User added");
      reset();
    } catch (ex) {
      const { message = "Username has been registered" } = ex;
      setMessage({ message });
    }
  }

  function renderDetail() {
    const policyProps = {
      value: password,
      isSubmitted: false,
    };

    return (
      <FormProvider {...form}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UsernameInput />
              </Grid>
              <Grid item xs={12}>
                <FullnameInput />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput />
                <PasswordPolicy {...policyProps} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </FormProvider>
    );
  }

  function renderRoles() {
    return (
      <FormProvider {...form}>
        <Card component="form" onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Checkboxs
                  items={ROLE_ACCESS}
                  name="access"
                  label="Access List"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button type="submit" color="primary">
              Create Sub Account
            </Button>
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  return (
    <Grid container spacing={2} justify="center">
      <Grid item lg={6} xs={12}>
        {loading ? <Loader /> : renderDetail()}
      </Grid>
      <Grid item lg={6} xs={12}>
        {loading ? <Loader /> : renderRoles()}
      </Grid>
    </Grid>
  );
}

export default AddUser;
