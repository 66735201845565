import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import useMount from "../hooks/useMount";
import Grid from "@material-ui/core/Grid";
import { PASSWORD_POLICY, REQUIRED_RULE } from "../helpers/Form";
import useForm from "../hooks/useForm";
import { useAuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";
import PasswordPolicy from "../components/PasswordPolicy";

const NewPasswordInput = styled(TextField).attrs({
  type: "password",
  label: "New Password",
  name: "newPassword",
  rules: REQUIRED_RULE,
})({});

// const ConfPasswordInput = styled(TextField).attrs({
//   type: "password",
//   label: "Confirmation Password",
//   name: "confpassword",
//   rules: REQUIRED_RULE,
// })({});

const PasswordInput = styled(TextField).attrs({
  type: "password",
  label: "Current Password",
  name: "password",
  rules: { ...REQUIRED_RULE, pattern: PASSWORD_POLICY.pattern },
})({});

function ChangePassword() {
  const { setPageTitle, setMessage } = useAppContext();
  const { changePassword } = useAuthContext();

  const form = useForm();

  const { handleSubmit, watch, reset } = form;
  const { newPassword } = watch();

  const [loading, setLoading] = useState();

  useMount(() => {
    setPageTitle("Change Password");
  });

  async function onSubmit(data) {
    setLoading(true);

    try {
      await changePassword(data);
      setMessage("Password Updated");
      reset();
    } catch (ex) {
      setMessage(ex);
    }
    setLoading(false);
  }

  function renderContent() {
    const policyProps = {
      value: newPassword,
      isSubmitted: false,
    };

    return (
      <FormProvider {...form}>
        <Card component="form" onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PasswordInput />
              </Grid>
              <Grid item xs={12}>
                <NewPasswordInput />
                <PasswordPolicy {...policyProps} />
              </Grid>
              {/* <Grid item xs={12}>
                <ConfPasswordInput />
              </Grid> */}
            </Grid>
          </CardContent>
          <CardActions>
            <Button color="primary" type="submit">
              Submit
            </Button>
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  return (
    <Grid container spacing={2} justify="center">
      <Grid item lg={6} xs={12}>
        {loading ? <Loader paper /> : renderContent()}
      </Grid>
    </Grid>
  );
}

export default ChangePassword;
