export const ROLE = {
  admin: "admin",
  master: "master",
  report: "report",
  agent: "agent",
  subaccount: "subaccount",
};

export const ALLOW_ROLES = {
  [ROLE.admin]: [ROLE.master, ROLE.admin],
  // [ROLE.master]: [ROLE.agent, ROLE.report],
  [ROLE.master]: [ROLE.agent],
  [ROLE.agent]: [ROLE.agent],
};

export const ACCESS = {
  Bet: Math.pow(2, 0),
  MatchReport: Math.pow(2, 1),
  WinLoseReport: Math.pow(2, 2),
  OddReport: Math.pow(2, 3),
  FightReport: Math.pow(2, 4),
  ChangePassword: Math.pow(2, 5),
  StrikeReport: Math.pow(2, 6),
  BetReport: Math.pow(2, 7),
};

export const ROLE_ACCESS = [
  {
    text: "Change Password",
    value: ACCESS.ChangePassword,
  },
  {
    text: "Place Bet",
    value: ACCESS.Bet,
  },
  {
    text: "Match Report (Old)",
    value: ACCESS.MatchReport,
  },
  {
    text: "Win/Lose Report",
    value: ACCESS.WinLoseReport,
  },
  {
    text: "Plate",
    value: ACCESS.OddReport,
  },
  {
    text: "Strike Report",
    value: ACCESS.StrikeReport,
  },
  {
    text: "Total Bet Report",
    value: ACCESS.BetReport,
  },
];
