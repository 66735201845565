import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Table from "../components/Table";
import { useAppContext } from "../context/AppContext";
import useMount from "../hooks/useMount";
import Filter from "./Filter";

import styled from "styled-components";
import useMatch from "../hooks/api/useMatch";
import Loader from "../components/Loader";
import { formatStandard } from "../helpers/DateTime";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

import useUser from "../hooks/api/useUser";
import { useAuthContext } from "../context/AuthContext";
import { ROLE } from "../constants/Role";

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Name = styled.span(({ head, theme }) => ({
  flex: 1,
  paddingLeft: head ? theme.spacing(5) : 0, // theme.spacing(1),
  paddingRight: head ? 0 : theme.spacing(5),
}));

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: status === "inactive" ? red[900] : green[500],
  marginRight: theme.spacing(1),
}));

function WinLoseMatchs() {
  const { setPageTitle } = useAppContext();
  const { user } = useAuthContext();
  const { role: userRole } = user ?? {};

  const history = useHistory();
  const [showFilter, setShowFilter] = useState();

  const { response: me } = useUser({
    auto: true,
    id: "me",
  });
  const { masterId, id: username } = me ?? {};
  const id = userRole === ROLE.master ? username : masterId;

  const { response: matchs, loading, refresh, search } = useMatch({
    id: `all/${id}`,
    activeOnly: false,
  });

  const response = matchs?.filter(s => !!s.score) || [];

  useMount(() => {
    setPageTitle("Win/Lose Report");
  });

  useEffect(() => {
    if (!id) {
      return;
    }

    refresh();
  }, [id]);

  const headCells = [
    {
      id: "name",
      numeric: false,
      label: "Name",
      custom: renderNameAndAction,
    },
    {
      id: "score",
      numeric: false,
      label: "Score",
    },
    {
      id: "playAt",
      numeric: false,
      label: "Match Date",
      format: (item) => formatStandard(item.playAt),
    },
  ];

  function renderNameAndAction(item) {
    const { name, status } = item;
    return (
      <StatusContent>
        <Dot status={status} />
        <Name>{name}</Name>
      </StatusContent>
    );
  }
  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async (data) => {
      setShowFilter();
      if (!data) {
        await refresh();
      } else {
        await search(data);
      }
    },
  };

  const tableProps = {
    headCells,
    items: response,
    rowsPerPage: 10,
    title: "Win/Lose Report",
    uniqueId: "username",
    defaultSort: "playAt",
    defaultOrder: "desc",
    onFilter: () => {
      setShowFilter(true);
    },
    onSelect: ({ id }) => {
      history.push(`/report/winlose/${id}`);
    },
  };

  return (
    <>
      {loading ? <Loader paper /> : <Table {...tableProps} />}
      <Filter {...filterProps} />
    </>
  );
}

export default WinLoseMatchs;
