import { IconButton } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import DeleteIcon from "@material-ui/icons/Delete";
import ReceiptIcon from "@material-ui/icons/Receipt";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Receipt from "../betting/Receipt";
import Confirmation from "../components/Confirmation";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { formatStandard } from "../helpers/DateTime";
import useDaysReceiptDateTxns from "../hooks/api/useDaysReceiptDateTxns";
import useMatch from "../hooks/api/useMatch";
import usePagedMatchWinTxns from "../hooks/api/usePagedMatchWinTxns";
import useTxn from "../hooks/api/useTxn";
import useMount from "../hooks/useMount";
import Filter from "./Filter";

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: status === "void" ? red[900] : green[500],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

function WinHistory() {
  const { setPageTitle, setMessage } = useAppContext();
  const [receipt, setReceipt] = useState();
  const [showFilter, setShowFilter] = useState();
  const [searchBody, setSearchBody] = useState();

  const [selected, setSelected] = useState();

  const { matchId } = useParams();

  const { response: all, loading } = usePagedMatchWinTxns({ matchId });
  // const all = [];
  // const loading = false;

  const response = useMemo(() => {
    if (!searchBody) {
      return all;
    }

    return all.filter((s) => formatSearch(s, searchBody));
  }, [all, searchBody]);

  const {
    response: txn,
    loading: txnLoading,
    update: updateTxn,
  } = useTxn({
    id: selected?.id,
  });

  const { response: match } = useMatch({
    id: matchId,
    auto: true,
  });

  const { name = "", score = "" } = match || {};

  useMount(() => {
    setPageTitle("Strike Report");
  });

  const headCells = [
    {
      id: "matchName",
      numeric: false,
      label: "Receipt No",
      custom: renderStatus,
      customHeader: renderStatusHeader,
    },
    { id: "paidBy", numeric: false, label: "Username" },
    { id: "totalAmount", numeric: true, label: "Bet" },
    { id: "strike", numeric: true, label: "Strike" },
    { id: "payout", numeric: true, label: "Payout" },
    { id: "remark", numeric: true, label: "Remark" },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  function renderStatusHeader(label) {
    return (
      <StatusContent>
        <Status>{label}</Status>
      </StatusContent>
    );
  }

  function renderStatus(item) {
    const { receiptNo } = item;

    return (
      <StatusContent>
        <Status>{receiptNo}</Status>
        <IconButton
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setReceipt(item);
          }}
        >
          <ReceiptIcon fontSize="inherit" />
        </IconButton>
      </StatusContent>
    );
  }

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async (data) => {
      setShowFilter();
      setSearchBody(data);
    },
  };

  const tableProps = {
    headCells,
    items: response,
    rowsPerPage: 10,
    defaultOrder: "desc",
    uniqueId: "id",
    title: `${name} (${score})`,
    defaultSort: "createdAt",
    onFilter: () => {
      setShowFilter(true);
    },
  };

  return (
    <>
      {loading ? <Loader paper /> : <Table {...tableProps} />}
      <Receipt
        item={receipt}
        match={match}
        onClose={() => setReceipt()}
        open={!!receipt}
        winTxn
      />
      <Filter {...filterProps} />

      <Confirmation
        title={`Void Bet - ${selected?.matchName}`}
        desc={`Total Amount: $${selected?.totalAmount}. Are you sure want to void bet now?`}
        open={!!selected}
        loading={txnLoading}
        onClose={() => setSelected()}
        onConfirm={async () => {
          setSelected();
          try {
            await updateTxn({ status: "void" });
            const index = response.findIndex(({ id }) => id === selected?.id);

            response[index].status = "void";
          } catch (ex) {
            setMessage({ message: "Match Closed" });
          }
        }}
      />
    </>
  );
}

const formatSearch = (item, body) => {
  const { matchAtStart, matchAtEnd, ...filter } = body;
  const { matchAt } = item;
  return (
    Object.entries(filter).every(([key, value]) =>
      !value ? true : contains(item[key], value)
    ) && inRangeWith(matchAt, matchAtStart, matchAtEnd)
  );
};

const inRangeWith = (value, start, end) => {
  if (!value) {
    return true;
  }

  if (!start && !end) {
    return true;
  }

  const validStart = moment(value).isSameOrAfter(moment(start).startOf("day"));
  const validEnd = moment(value).isSameOrBefore(moment(end).endOf("day"));

  if (start && end) {
    return validStart && validEnd;
  }

  return start ? validStart : validEnd;
};

// string, date, number
// string & date = contain

// date = object
const contains = (s1, s2 = "") => {
  // date
  if (typeof s1 === "object") {
    return moment(s1).isSame(s2, "day");
  }

  return `${s1}`.toLowerCase().includes(`${s2}`.toLowerCase());
};

export default WinHistory;
