import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useMatchByDate() {
  const { response, loading, get } = useAuthFetch();

  function refresh({ startDate, endDate }) {
    const url = `${API_URL}/match/date/${startDate}/to/${endDate}`;
    return get({ url });
  }

  return { response, loading, refresh };
}

export default useMatchByDate;
